<template>
  <b-modal
    id="resumePrescription"
    hide-header
    centered
    hide-footer
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
  >
    <div class="tw-flex tw-justify-end tw-cursor-pointer">
      <b-icon
        icon="x-circle-fill"
        variant="primary"
        font-scale="1.5"
        @click="$bvModal.hide('resumePrescription')"
      ></b-icon>
    </div>
    <h1 class="heading p-4 mb-0">Unpause or Reschedule Prescription</h1>
    <b-row align-v="center" class="p-4">
      <b-col cols="12">
        Do you want to unpause this prescription or to reschedule?
      </b-col>
    </b-row>

    <b-row align-h="center" class="p-4">
      <b-col cols="6">
        <b-button
          pill
          block
          @click="onPrescriptionReschedule"
          variant="primary"
          :disabled="processing"
        >
          Reschedule
        </b-button>
      </b-col>
      <b-col cols="6" class="mb-3">
        <b-button
          pill
          block
          :disabled="processing"
          @click="onPrescriptionUnpause"
          variant="outline-primary"
        >
          <b-spinner v-if="processing" small></b-spinner>
          <span v-else>Unpause</span>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onPrescriptionUnpause() {
      this.$emit("resume");
    },
    onPrescriptionReschedule() {
      this.$emit("reschedule");
    },
  },
};
</script>
