<template>
  <b-modal
    id="prescribe-estrogen-restart-alert"
    hide-header
    hide-header-close
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <div class="icon-logo"></div>
      </b-col>
    </b-row>
    <div class="tw-float-right tw-w-full lg:tw-w-4/5">
      <div>
        <h2 class="mb-3 heading tw-text-2xl">
          Patient has a Progesterone prescription cancelled by Stripe within the
          last 30 days. Restarting this Estrogen prescription will first restart
          and charge the following prescription before proceeding.
        </h2>
      </div>

      <b-row class="my-4">
        <b-col cols="8" class="mb-2">
          <b-button
            block
            pill
            variant="primary"
            @click="$emit('prescribe')"
            :disabled="processing"
          >
            <b-spinner v-if="processing" small></b-spinner>
            <span v-else>Continue</span>
          </b-button>
        </b-col>
        <b-col cols="8">
          <b-button
            block
            pill
            variant="outline-primary"
            @click="$bvModal.hide('prescribe-estrogen-alert')"
          >
            <span>Cancel</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { format, fromUnixTime } from "date-fns";
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
