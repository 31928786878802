<template>
  <b-modal
    id="edit-prescription"
    hide-header
    hide-header-close
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    @hide="onHide"
    @shown="onShown"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <div class="icon-logo"></div>
      </b-col>
    </b-row>
    <div class="tw-w-full">
      <div>
        <h2 class="mb-3 heading tw-text-2xl">Edit prescription</h2>
      </div>

      <!-- v-if="prescriptions.length > 0" -->
      <b-row align-h="center" v-if="isProcessing && !isLoaded">
        <b-col cols="12" class="text-center">
          <b-spinner variant="primary"></b-spinner>
          <h2>Fetching dosages...</h2>
          <b-button
            pill
            variant="outline-primary"
            @click="$bvModal.hide('edit-prescription')"
          >
            <span>Nevermind</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center" v-else>
        <b-col cols="12">
          <h4 class="mr-4 text-primary">Prescription Order</h4>
          <b-alert :show="alert.show" :variant="alert.type">{{
            alert.message
          }}</b-alert>
          <b-card class="prescription-details" style="position: relative">
            <b-row class="mb-4">
              <b-col cols="12" md="6">
                <label for="subscription_startdate" class="text-primary">
                  <small>Subscription Start Date</small>
                </label>
                <b-form-datepicker
                  id="subscription_startdate"
                  v-model="subscriptionstartdate"
                  :date-disabled-fn="subscriptionStartDateDisabled"
                ></b-form-datepicker>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="update-order">
            <div
              class="
                tw-grid tw-grid-cols-10 tw-mb-4 tw-space-y-4
                lg:tw-space-y-0
                md:tw-space-x-4
                tw-place-items-stretch
              "
            >
              <div
                role="group"
                class="
                  tw-justify-between tw-flex tw-flex-col tw-col-span-10
                  md:tw-col-span-4
                "
              >
                <label for="prescription" class="text-primary">
                  <small>Prescription</small>
                </label>
                <v-select
                  class="prescription-select"
                  id="prescription"
                  :calculate-position="withPopper"
                  v-model="prescription.productid"
                  label="text"
                  :options="prescriptions"
                  :reduce="(prescriptions) => prescriptions.value"
                  @input="onSelectPrescription(prescription)"
                  :disabled="true"
                  placeholder="Fetching data..."
                >
                  <template #open-indicator="{ attributes }">
                    <b-icon
                      v-bind="attributes"
                      icon="chevron-down"
                      variant="primary"
                    ></b-icon>
                  </template>
                </v-select>
              </div>
              <div
                role="group"
                class="
                  tw-justify-between tw-flex tw-flex-col tw-col-span-10
                  md:tw-col-span-2
                "
              >
                <label for="dosage" class="text-primary">
                  <small>Dosage</small>
                </label>
                <v-select
                  class="prescription-select"
                  id="dosage"
                  :calculate-position="withPopper"
                  v-model="prescription.dosage"
                  label="text"
                  :options="
                    dosages[productid] &&
                    dosages[productid].filter(({ value }) => value !== '')
                  "
                  @input="changeDosage(prescription)"
                  :reduce="(dosages) => dosages.value"
                  :disabled="!prescription.productid"
                >
                  <template #open-indicator="{ attributes }">
                    <b-icon
                      v-bind="attributes"
                      icon="chevron-down"
                      variant="primary"
                    ></b-icon>
                  </template>
                </v-select>
              </div>
              <div
                role="group"
                class="
                  tw-justify-between tw-flex tw-flex-col tw-col-span-10
                  md:tw-col-span-3
                "
              >
                <label for="unit" class="text-primary">
                  <small>Unit</small>
                </label>
                <v-select
                  class="prescription-select"
                  id="unit"
                  :calculate-position="withPopper"
                  v-model="prescription.pricingid"
                  label="text"
                  :options="
                    units[productid] &&
                    units[productid].filter(
                      (unit) => unit.dosage === prescription.dosage
                    )
                  "
                  :reduce="(units) => units.value"
                  @input="selectedUnit(productid, prescription.pricingid)"
                  clearable
                  :disabled="!prescription.dosage"
                >
                  <template #open-indicator="{ attributes }">
                    <b-icon
                      v-bind="attributes"
                      icon="chevron-down"
                      variant="primary"
                    ></b-icon>
                  </template>
                </v-select>
              </div>
              <div
                role="group"
                class="
                  tw-justify-between tw-flex tw-flex-col tw-col-span-10
                  md:tw-col-span-1
                "
              >
                <label for="unit" class="text-primary">
                  <small>Refill</small>
                </label>
                <b-form-input
                  v-model.number="prescription.refillinput"
                  id="refill"
                  type="number"
                ></b-form-input>
              </div>
            </div>

            <b-row>
              <b-col cols="12">
                <div role="group">
                  <label for="textarea" class="text-primary">
                    <small>Doctor Notes</small>
                  </label>
                  <b-form-textarea
                    id="textarea"
                    v-model="prescription.notes"
                    class="pl-0"
                    rows="3"
                    max-rows="6"
                    style="overflow: auto"
                  ></b-form-textarea>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="isLoaded">
        <b-col cols="6">
          <b-button
            block
            pill
            variant="outline-primary"
            @click="$bvModal.hide('edit-prescription')"
          >
            <span>Nevermind</span>
          </b-button>
        </b-col>
        <b-col cols="6" class="mb-2">
          <b-button
            block
            pill
            variant="primary"
            @click="() => onEditPrescription(false)"
            :disabled="isProcessing"
          >
            <b-spinner v-if="isProcessing" small></b-spinner>
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  format,
  parse,
  sub,
  getUnixTime,
  fromUnixTime,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
import popper from "@/assets/js/popper.js";

export default {
  props: {
    selectedPrescription: {
      type: Object,
      default: () => ({
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: "",
      }),
    },
  },
  mixins: [popper],
  data() {
    return {
      subscriptionstartdate: format(new Date(), "yyyy-MM-dd"),
      prescriptions: [],
      dosages: [],
      units: [],
      productid: "",
      prescription: {
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: "",
      },
      oldPrescription: {
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: "",
      },
      patientId: "",
      prescriptionOrderId: "",
      prescriptionversion: "",
      isProcessing: false,
      isLoaded: false,
      hasDuplicate: false,
      alert: {
        show: false,
        type: "info",
        message: "",
      },
    };
  },
  watch: {
    selectedPrescription: {
      handler(val) {
        this.prescription = val;
        if (val.nextshipmentdate) {
          this.subscriptionstartdate = format(
            val.nextshipmentdate * 1000,
            "yyyy-MM-dd"
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    parseDate(date, stringFormat) {
      if (date) {
        return format(fromUnixTime(date), stringFormat);
      }
    },
    subscriptionStartDateDisabled(ymd, date) {
      return sub(new Date(), { days: 1 }) > date;
    },
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    async onShown() {
      try {
        this.alert = {
          show: false,
          type: "info",
          message: "",
        };
        this.isProcessing = true;
        this.patientId = this.prescription.patientid;
        this.prescriptionOrderId = this.prescription.id;
        await this.getPrescriptions();
        const res = await this.$store.dispatch(
          "prescription/getPrescriptionOrder",
          {
            patientId: this.patientId,
            prescriptionOrderId: this.prescriptionOrderId,
          }
        );
        const selectedPricing = this.units[res.productid].find(
          (unit) =>
            unit.subscriptiontype === res.subscriptiontype &&
            unit.dosage === res.dosage
        );
        if (selectedPricing) {
          this.prescription.dosage = selectedPricing.dosage;
          this.prescription.pricingid = selectedPricing.value;
          this.prescription.productid = res.productid;
          this.prescription.unit = selectedPricing.text;
          this.prescription.refillinput = res.refillinput;
          this.prescription.notes = selectedPricing.notes;
        }

        this.productid = res.productid;
        this.prescriptionversion = res.prescriptionversion;

        this.oldPrescription = { ...this.prescription };
        // this.oldSubscriptionstartdate = this.subscriptionstartdate;

        this.isProcessing = false;
        this.isLoaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    async getPrescriptions() {
      const products = await this.$store.dispatch(
        "prescription/getProductDosageAndUnit",
        { patientid: this.patientId }
      );

      this.prescriptions = products.map(
        ({ id: productid, details: dosages, name }) => {
          this.dosages[productid] = [];
          this.units[productid] = [];
          for (let i = 0; i < dosages.length; i++) {
            let dosage = dosages[i];
            this.dosages[productid].push({
              value: dosage.dosage,
              text: dosage.dosagedisplay,
            });

            for (let j = 0; j < dosage.detail.length; j++) {
              let unit = dosage.detail[j];
              this.units[productid].push({
                value: unit.id,
                text: unit.unit,
                subscriptiontype: unit.subscriptiontype,
                notes: unit.notes,
                dosage: dosage.dosage,
                default: dosage.default ? true : false,
                name,
                defaultrefill: unit.defaultrefill,
              });
            }
          }

          return {
            value: productid,
            text: name,
          };
        }
      );
    },
    onSelectPrescription(order) {
      this.productid = order.productid;
      this.prescription.pricingid = "";
      this.prescription.dosage = "";
      this.prescription.notes = "";
    },
    selectedUnit(productid, pricingid) {
      let unit = this.units[productid].filter((o) => {
        return o.value === pricingid;
      });

      let order = this.prescription;

      if (productid == order.productid) {
        order.unit = unit[0].text;
      }

      let selectedDosage = unit.find((val) => val.dosage === order.dosage);
      if (selectedDosage) {
        order.notes = selectedDosage.notes;

        if (
          selectedDosage?.defaultrefill ||
          selectedDosage?.defaultrefill === 0
        ) {
          order.refillinput = selectedDosage?.defaultrefill;
        } else {
          if (selectedDosage.subscriptiontype === "Monthly") {
            order.refillinput = 3;
          } else if (selectedDosage.subscriptiontype === "Quarterly") {
            order.refillinput = 1;
          }
        }
      }
    },
    changeDosage(order) {
      let unit = this.units[order.productid].find((o) => {
        return o.dosage === order.dosage;
      });

      // if (!unit) {
      //   // Change unit automatically for DHEA when dosage changes
      //   unit = this.units[order.productid].find((o) => {
      //     return o.dosage === order.dosage;
      //   });
      // }

      this.prescription.pricingid = unit.value;
      this.prescription.unit = unit.text;
      this.prescription.notes = unit.notes;

      if (unit?.defaultrefill || unit?.defaultrefill === 0) {
        this.prescription.refillinput = unit.defaultrefill;
        return;
      }

      if (unit.name === "Blossom") {
        this.prescription.refillinput = 0;
      } else {
        if (unit.subscriptiontype === "Monthly") {
          this.prescription.refillinput = 3;
        } else if (unit.subscriptiontype === "Quarterly") {
          this.prescription.refillinput = 1;
        }
      }
    },
    async onEditPrescription(force) {
      try {
        this.alert = {
          show: false,
          type: "info",
          message: "",
        };
        this.isProcessing = true;
        await this.$store.dispatch("prescription/schedulePrescription", {
          prescriptionid: this.prescriptionOrderId,
          patientid: this.patientId,
          payload: {
            dosage: this.prescription.dosage,
            notes: this.prescription.notes,
            pricingid: this.prescription.pricingid,
            productid: this.prescription.productid,
            unit: this.prescription.unit,
            refillinput: this.prescription.refillinput,
            timestampsubscriptionstartdate: getUnixTime(
              parse(
                this.getDateStringWithCurrentTime(this.subscriptionstartdate),
                "yyyy-MM-dd H:m:s",
                new Date()
              )
            ),
            ...(this.hasDuplicate && {
              inaddition: true,
            }),
            ...(force && {
              force: true,
            }),
          },
        });
        this.isProcessing = false;

        this.$bvModal.hide("edit-prescription");
        this.$bvModal.hide("prescribe-estrogen-edit-alert");
        this.$emit("edit");
      } catch (e) {
        console.error(e);
        this.isProcessing = false;
        this.alertMessage("warning", e.message);
        if (
          e?.message?.includes(
            "You are prescribing Estrogen to a patient that has not been prescribed Progesterone."
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-edit-alert");
          return;
        }
      }
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    onHide() {
      this.$emit("modal-close");
    },
  },
};
</script>
