<template>
  <b-card header-tag="header" no-body class="mb-0 table-card" style="border: 0">
    <template v-slot:header v-if="!noHeader">
      <b-row align-v="center">
        <b-col v-if="title" :cols="hasSearch ? 'auto' : ''">
          <strong>{{ title }}</strong>
        </b-col>
        <b-col v-if="hasSearch">
          <div class="input-group input-group-flush">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fe fe-search"></i>
              </span>
            </div>
            <input
              v-model="filter"
              class="form-control list-search"
              type="search"
              placeholder="Search"
            />
          </div>
        </b-col>
        <b-col cols="12" md="auto">
          <b-row align-v="center">
            <b-col>
              <template v-if="filter"
                >Showing <b>{{ rows }}</b>
                <template v-if="rows == 1 || rows == 0"> result</template
                ><template v-else> results</template> for “{{
                  filter
                }}”</template
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
    <b-table
      ref="selectableTable"
      responsive
      select-mode="single"
      :selectable="isSelectable"
      :sticky-header="scrollable ? scrollHeight : false"
      :fields="fields"
      :items="items"
      @filtered="onFiltered"
      :filter="hasSearch ? filter : ''"
      :current-page="hasPagination ? currentPage : ''"
      :per-page="hasPagination ? itemPerPage : ''"
      @row-selected="onRowSelected"
      @scroll.native="onScroll"
      :class="['table-nowrap billing-table', className]"
    >
      <template v-slot:cell(type)="data">
        {{ data.item.type === "1YEAR" ? "1 Year" : "10 Week" }}
      </template>
      <template v-slot:cell(action)="data">
        <a
          href=""
          v-if="data.item.followupstatus === 'SCHEDULED'"
          @click.prevent="
            openFollowupModal({
              patientid: data.item.patientid,
              patientfollowupid: data.item.id,
              followupdatestamp: data.item.followupdatestamp,
            })
          "
        >
          Update
        </a>
        <a
          class="pl-3"
          href=""
          v-if="
            (items.length > 1 &&
              data.item.followupstatus === 'SCHEDULED' &&
              scheduledCount > 1) ||
            data.item.followupstatus === 'SENT'
          "
          @click.prevent="
            openFollowupDeleteModal({
              patientid: data.item.patientid,
              patientfollowupid: data.item.id,
              followupdatestamp: data.item.followupdatestamp,
            })
          "
        >
          Delete
        </a>
      </template>
    </b-table>

    <b-modal
      id="followupupdate"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <ValidationObserver ref="followupDeleteForm" v-slot="{ handleSubmit }">
        <b-row style="position: relative">
          <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
            <svg
              width="30"
              height="27"
              viewBox="0 0 30 27"
              fill="#1A6A72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
                fill="#1A6A72"
              />
            </svg>
          </b-col>
          <b-col cols="12" sm="8" offset-sm="2" class="py-6">
            <h1 class="heading mb-4" style="line-height: 30px">
              Update follow-up date
            </h1>

            <b-row class="mb-4">
              <b-col cols="12" class="mb-4">
                <ValidationProvider
                  name="followup"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="followup" class="text-primary">
                    <small>Schedule Follow-up</small>
                  </label>
                  <v-select
                    id="followup"
                    v-model="selectedFollowUpOption"
                    :calculate-position="withPopper"
                    label="text"
                    :options="followUpOptions"
                    :reduce="(followUpOptions) => followUpOptions.value"
                  >
                    <template #open-indicator="{ attributes }">
                      <b-icon
                        v-bind="attributes"
                        icon="chevron-down"
                        variant="primary"
                      ></b-icon>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col cols="12">
                <ValidationProvider
                  name="followup_date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="followup_date" class="text-primary">
                    <small>Follow-up Date</small>
                  </label>
                  <b-form-datepicker
                    id="followup_date"
                    :disabled="selectedFollowUpOption !== 'Manual'"
                    :date-disabled-fn="dateDisabled"
                    v-model="followupDate"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  @click="$bvModal.hide('followupupdate')"
                  variant="primary"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  :disabled="isProcessing"
                  @click="handleSubmit(onFollowupUpdate)"
                  variant="outline-primary"
                >
                  <b-spinner v-if="isProcessing" small></b-spinner>
                  <span v-else>Confirm</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-modal>

    <b-modal
      id="followupdelete"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <ValidationObserver ref="followupForm" v-slot="{ handleSubmit }">
        <b-row style="position: relative">
          <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
            <svg
              width="30"
              height="27"
              viewBox="0 0 30 27"
              fill="#1A6A72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
                fill="#1A6A72"
              />
            </svg>
          </b-col>
          <b-col cols="12" sm="8" offset-sm="2" class="py-6">
            <h1 class="heading mb-4" style="line-height: 30px">
              Delete follow-up date
            </h1>

            <b-row class="mb-4">
              <b-col cols="12">
                {{ followupDate }}
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  @click="$bvModal.hide('followupdelete')"
                  variant="primary"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  :disabled="isProcessing"
                  @click="handleSubmit(onFollowupDelete)"
                  variant="outline-primary"
                >
                  <b-spinner v-if="isProcessing" small></b-spinner>
                  <span v-else>Confirm</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-modal>

    <b-modal
      id="followupschedule"
      hide-header
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="$emit('update:schedule-followup', false)"
    >
      <ValidationObserver ref="followupScheduleForm" v-slot="{ handleSubmit }">
        <b-row style="position: relative">
          <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
            <svg
              width="30"
              height="27"
              viewBox="0 0 30 27"
              fill="#1A6A72"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
                fill="#1A6A72"
              />
            </svg>
          </b-col>
          <b-col cols="12" sm="8" offset-sm="2" class="py-6">
            <h1 class="heading mb-4" style="line-height: 30px">
              Schedule a follow-up
            </h1>
            <b-alert :show="scheduleAlert.show" variant="warning">{{
              scheduleAlert.message
            }}</b-alert>

            <b-row class="mb-4">
              <b-col cols="12" class="mb-4">
                <ValidationProvider
                  name="followup"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="followup" class="text-primary">
                    <small>Schedule Follow-up</small>
                  </label>
                  <v-select
                    id="followup"
                    v-model="selectedScheduleFollowupOption"
                    :calculate-position="withPopper"
                    label="text"
                    :options="followUpOptions"
                    :reduce="(followUpOptions) => followUpOptions.value"
                  >
                    <template #open-indicator="{ attributes }">
                      <b-icon
                        v-bind="attributes"
                        icon="chevron-down"
                        variant="primary"
                      ></b-icon>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
              <b-col cols="12">
                <ValidationProvider
                  name="followup_date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="followup_date" class="text-primary">
                    <small>Follow-up Date</small>
                  </label>
                  <b-form-datepicker
                    id="followup_date"
                    :disabled="selectedScheduleFollowupOption !== 'Manual'"
                    :date-disabled-fn="dateDisabled"
                    v-model="scheduledFollowupDate"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  @click="$bvModal.hide('followupschedule')"
                  variant="primary"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col cols="12" sm="6">
                <b-button
                  pill
                  block
                  :disabled="isProcessing"
                  @click="handleSubmit(onFollowupSchedule)"
                  variant="outline-primary"
                >
                  <b-spinner v-if="isProcessing" small></b-spinner>
                  <span v-else>Confirm</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-modal>
  </b-card>
</template>

<script>
import {
  format,
  add,
  fromUnixTime,
  getUnixTime,
  parse,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
import popper from "@/assets/js/popper.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  mixins: [popper],
  props: {
    title: {
      type: String,
    },
    className: {
      type: String,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    scrollHeight: {
      type: String,
      default: "400px",
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    itemPerPage: {
      type: Number,
      default: 5,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: "",
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
    hasChat: {
      type: Boolean,
      default: false,
    },
    scheduleFollowup: {
      type: Boolean,
      default: false,
    },
    bvToast: {
      type: Object,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      rows: 1,
      prescriptionid: "",
      prescription: {},
      currentPage: 1,
      filter: null,
      selected: [],
      rowSelect: [],
      selectAll: false,
      iframeHeight: "",

      isProcessing: false,
      followUpOptions: [
        { value: "3 Month", text: "3 Months" },
        { value: "1 Year", text: "1 Year" },
        { value: "Manual", text: "Manual" },
      ],

      // Update Follow-up
      selectedFollowUpOption: "Manual",
      followupDate: "",

      // Schedule Follow-up
      selectedScheduleFollowupOption: "Manual",
      scheduledFollowupDate: new Date(),

      followupPatientId: "",
      followupId: "",
      scheduleAlert: {
        show: false,
        message: "",
      },
    };
  },
  mounted() {
    this.$forceUpdate();
    this.matchHeight();

    window.addEventListener("resize", () => {
      this.matchHeight();
    });
  },
  computed: {
    scheduledCount() {
      // Filter out non-scheduled followups
      let scheduledArray = this.items.filter(
        (item) => item.followupstatus == "SCHEDULED"
      );
      return scheduledArray.length;
    },
  },
  async created() {
    this.rows = this.items.length;
    await this.$store.dispatch(
      "patients/getPatientFollowupSchedule",
      this.$route.params.id
    );
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    matchHeight() {
      let appHeight = window.innerHeight - 18;
      this.iframeHeight = appHeight;
    },
    onRowSelected(rows) {
      if (Object.keys(rows).length > 0) {
        this.rowSelect = rows;
        this.$emit("selectedPrescription", this.rowSelect[0].data);
      } else {
        this.$emit("selectedPrescription", {});
      }
    },
    onFiltered(filteredItems) {
      if (this.filter) {
        this.rows = filteredItems.length;
        return;
      } else {
        this.rows = this.items.length;
        return;
      }
    },
    onScroll(e) {
      this.$emit("scroll", e);
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    openFollowupModal({ patientid, patientfollowupid, followupdatestamp }) {
      this.$bvModal.show("followupupdate");
      this.followupDate = fromUnixTime(followupdatestamp);
      this.followupPatientId = patientid;
      this.followupId = patientfollowupid;
    },
    openFollowupDeleteModal({
      patientid,
      patientfollowupid,
      followupdatestamp,
    }) {
      this.$bvModal.show("followupdelete");
      this.followupDate = fromUnixTime(followupdatestamp);
      this.followupPatientId = patientid;
      this.followupId = patientfollowupid;
    },
    async onFollowupUpdate() {
      try {
        this.isProcessing = true;
        await this.$store.dispatch("patients/updatePatientFollowupSchedule", {
          patientid: this.followupPatientId,
          patientfollowupid: this.followupId,
          data: {
            followupdatestamp: getUnixTime(
              parse(
                this.getDateStringWithCurrentTime(this.followupDate),
                "yyyy-MM-dd H:m:s",
                new Date()
              )
            ),
          },
        });

        this.$emit("refetch");
        this.$bvModal.hide("followupupdate");
      } catch (e) {
        console.error(e);
      } finally {
        this.isProcessing = false;
        this.$nextTick(() => {
          this.$refs.followupForm?.reset();
        });
      }
    },
    async onFollowupSchedule() {
      try {
        this.isProcessing = true;
        this.scheduleAlert.show = false;
        let ret = await this.$store.dispatch(
          "patients/schedulePatientFollowup",
          {
            patientid: this.$route.params.id,
            data: {
              followupdatestamp: getUnixTime(
                parse(
                  this.getDateStringWithCurrentTime(this.scheduledFollowupDate),
                  "yyyy-MM-dd H:m:s",
                  new Date()
                )
              ),
            },
          }
        );
        if (!(ret instanceof Error)) {
          this.$emit("refetch");
          this.$bvModal.hide("followupschedule");
        } else {
          this.scheduleAlert.show = true;
          this.scheduleAlert.message = ret;
          this.$emit("refetch");
        }
      } catch (e) {
        this.scheduleAlert.show = true;
        this.scheduleAlert.message = e;
        console.error(e);
      } finally {
        this.isProcessing = false;
        this.$nextTick(() => {
          this.$refs.followupForm?.reset();
        });
      }
    },
    async onFollowupDelete() {
      try {
        this.isProcessing = true;
        let data = await this.$store.dispatch(
          "patients/deletePatientFollowupSchedule",
          {
            patientid: this.followupPatientId,
            patientfollowupid: this.followupId,
          }
        );

        if (data == "ok") {
          this.$emit("refetch");
          this.$bvModal.hide("followupdelete");
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isProcessing = false;
      }
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
  },
  watch: {
    selected(newValue) {
      this.selectAll = newValue.length == this.rows ? true : false;
      if (this.hasPagination)
        this.selectAll = newValue.length == this.indexLast ? true : false;
    },
    selectedFollowUpOption: {
      handler(val) {
        if (val === "3 Month") {
          this.followupDate = format(
            add(new Date(), { days: 3 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else if (val === "1 Year") {
          this.followupDate = format(
            add(new Date(), { days: 13 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else if (val === "Manual") {
          this.followupDate = format(
            add(new Date(), { days: 1 }),
            "yyyy-MM-dd"
          );
        }
      },
      immediate: true,
    },
    selectedScheduleFollowupOption: {
      handler(val) {
        if (val === "3 Month") {
          this.scheduledFollowupDate = format(
            add(new Date(), { months: 3 }),
            "yyyy-MM-dd"
          );
        } else if (val === "1 Year") {
          this.scheduledFollowupDate = format(
            add(new Date(), { years: 1 }),
            "yyyy-MM-dd"
          );
        } else if (val === "Manual") {
          this.scheduledFollowupDate = format(
            add(new Date(), { days: 1 }),
            "yyyy-MM-dd"
          );
        }
      },
      immediate: true,
    },
    scheduleFollowup(val) {
      if (val) {
        this.$bvModal.show("followupschedule");
      } else {
        this.scheduleAlert.show = false;
        this.scheduledFollowupDate = new Date();
      }
    },
  },
};
</script>

<style scoped>
/deep/.pagination {
  margin-bottom: 0;
}

/deep/.page-item.active {
  display: none;
}

/deep/.page-link[role="menuitemradio"] {
  display: none;
}

/deep/.page-item.active .page {
  display: none;
}

/deep/.table thead th,
/deep/.table.b-table > thead > tr > .table-b-table-default,
.table.b-table > tbody > tr > .table-b-table-default,
.table.b-table > tfoot > tr > .table-b-table-default {
  background-color: rgb(243, 247, 248);
  border-bottom: 1px solid #1a6a72;
}

/deep/.table.b-table > thead > tr > .table-b-table-default {
  color: #828282;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0.75px;
}

/deep/.table thead th:focus {
  user-select: none;
}
</style>

<style lang="scss">
.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: $primary;
  color: $white;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  border-top: 0;
}

.table thead th {
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.card.table-card {
  border: 0;
  background-color: rgb(243, 247, 248);
}

.table thead th {
  border: 0;
}

.table tbody td {
  border-bottom: 0;
  border-top: 1px solid rgba(26, 106, 114, 0.2);
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-green {
  background-color: $green;
}

.dot-red {
  background-color: $red;
}

.dot-yellow {
  background-color: #f5d83e;
}
</style>
