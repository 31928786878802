var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "discussPrescription",
        "hide-header": "",
        centered: "",
        "hide-footer": "",
        size: "md",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "no-enforce-focus": "",
      },
    },
    [
      _c("h1", { staticClass: "heading p-4 mb-0" }, [
        _vm._v("Discuss Patient Prescription"),
      ]),
      _c(
        "b-row",
        { staticClass: "p-4", attrs: { "align-v": "center" } },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _vm._v(" The patient would like to discuss prior to prescribing. "),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "p-4", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: { pill: "", block: "", variant: "outline-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("discussPrescription")
                    },
                  },
                },
                [_c("span", [_vm._v("Close")])]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: { pill: "", block: "", variant: "primary" },
                  on: { click: _vm.onPrescriptionDiscuss },
                },
                [_c("span", [_vm._v("Message Patient")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }