var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c(
      "div",
      {
        staticClass:
          "card-prescription card d-flex flex-column mb-0 tw-border-none",
      },
      [
        _c(
          "div",
          {
            staticClass: "card-container-top d-flex flex-column",
            class: `bg-${_vm.statusColor}-light`,
          },
          [
            _c("div", { staticClass: "card-title" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-start",
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "mb-2 h1 pr-2",
                      staticStyle: { "font-size": "22px" },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.prescription.medicinename) + " "),
                      _vm.prescription.dosage
                        ? _c("span", [
                            _vm._v("| " + _vm._s(_vm.prescription.dosage)),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "tw-flex tw-flex-col tw-shrink-0" },
                    [
                      _c(
                        "small",
                        {
                          staticClass: "text-muted",
                          staticStyle: { "flex-shrink": "0" },
                        },
                        [_vm._v(" " + _vm._s(_vm.prescription.unit) + " ")]
                      ),
                      _vm.prescription.airtablestatus === "Processing"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "badge status-processing tw-py-1 tw-rounded-full tw-px-2",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.prescription.airtablestatus) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              !_vm.noRefills && _vm.prescriptionStatus.text !== "Draft"
                ? _c("p", { class: `text-${_vm.statusColor}` }, [
                    _vm.prescription.paymentstatus === "FAILED" &&
                    _vm.prescription.paidrefills === 0 &&
                    _vm.prescription.refillinput === 0
                      ? _c("span", [_vm._v(" No refills ")])
                      : _vm.prescription.paymentstatus === "FAILED" &&
                        _vm.prescription.paidrefills === 0
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.refillsLeft - 1) +
                              " out of " +
                              _vm._s(_vm.prescription.refills - 1) +
                              " Refills Left "
                          ),
                        ])
                      : _vm.prescription.refills && _vm.prescription.paidrefills
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.refillsLeft) +
                              " out of " +
                              _vm._s(_vm.prescription.refills - 1) +
                              " Refills Left "
                          ),
                        ])
                      : _vm.prescriptionStatus.text === "Waiting" ||
                        _vm.prescriptionStatus.text === "Processing" ||
                        _vm.prescriptionStatus.text === "Scheduled"
                      ? _c("span", [
                          _vm._v(
                            " Refills: " +
                              _vm._s(_vm.prescription.refillinput) +
                              " "
                          ),
                        ])
                      : _c("span", [_vm._v("No refills yet")]),
                  ])
                : _c("p", { class: `text-${_vm.statusColor}` }, [
                    _c("span", [
                      _vm._v(
                        " Refills: " +
                          _vm._s(_vm.prescription.refillinput) +
                          " "
                      ),
                    ]),
                  ]),
            ]),
            _c("div", { staticClass: "tw-grid tw-grid-cols-2" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("p", { staticClass: "mb-0 text-muted info-title" }, [
                  _vm._v("Prescribed"),
                ]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.prescription.timestampprescibed
                          ? _vm.parseUnixDate(
                              _vm.prescription.timestampprescibed,
                              "MMMM d, yyyy"
                            )
                          : "-"
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", [
                _c("p", { staticClass: "mb-0 text-muted info-title" }, [
                  _vm._v("Treatment Start"),
                ]),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-items-center tw-space-x-1" },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.prescription.scheduleddatetimestamp
                              ? _vm.parseUnixDate(
                                  _vm.prescription.scheduleddatetimestamp,
                                  "MMMM d, yyyy"
                                )
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _vm.prescription.scheduleddatetimestamp &&
                    _vm.isBeforeScheduledDate
                      ? _c("b-form-datepicker", {
                          attrs: {
                            size: "sm",
                            "button-only": "",
                            right: "",
                            "aria-controls": "example-input",
                            "button-variant": "primary",
                            id: "edit-treatmentstart",
                            "date-format-options": {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            },
                            "hide-header": true,
                            "date-disabled-fn": _vm.nextShipmentDateDisabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("b-icon", {
                                      staticClass: "tw-cursor-pointer",
                                      staticStyle: {
                                        width: "15px",
                                        height: "15px",
                                      },
                                      attrs: {
                                        icon: "pencil-square",
                                        variant: "primary",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3790804701
                          ),
                          model: {
                            value: _vm.scheduledDate,
                            callback: function ($$v) {
                              _vm.scheduledDate = $$v
                            },
                            expression: "scheduledDate",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "mb-0 text-muted info-title" }, [
                  _vm._v("Next Refill"),
                ]),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-items-center tw-space-x-1" },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.prescription.nextshipmentdate
                              ? _vm.parseUnixDate(
                                  _vm.prescription.nextshipmentdate,
                                  "MMMM d, yyyy"
                                )
                              : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _vm.prescription.nextshipmentdate
                      ? _c("b-form-datepicker", {
                          attrs: {
                            size: "sm",
                            "button-only": "",
                            right: "",
                            "aria-controls": "example-input",
                            "button-variant": "primary",
                            id: "edit-refilldate",
                            "date-format-options": {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            },
                            "hide-header": true,
                            "date-disabled-fn": _vm.nextShipmentDateDisabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("b-icon", {
                                      staticClass: "tw-cursor-pointer",
                                      staticStyle: {
                                        width: "15px",
                                        height: "15px",
                                      },
                                      attrs: {
                                        icon: "pencil-square",
                                        variant: "primary",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3790804701
                          ),
                          model: {
                            value: _vm.nextShipmentDate,
                            callback: function ($$v) {
                              _vm.nextShipmentDate = $$v
                            },
                            expression: "nextShipmentDate",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "mb-0 text-muted info-title" }, [
                  _vm._v("Last Refill Ends"),
                ]),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.prescription.actualenddate
                          ? _vm.parseUnixDate(
                              _vm.prescription.actualenddate,
                              "MMMM d, yyyy"
                            )
                          : "-"
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "card-container-bottom text-white",
            class: `bg-${_vm.statusColor}`,
          },
          [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _vm.currentDoctor.id
                ? _c("div")
                : _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.prescriptionStatus.text === "Paused"
                                          ? "Resume prescription"
                                          : _vm.prescriptionStatus.text ===
                                            "Failed"
                                          ? "Retry prescription"
                                          : "Play prescription"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-icon", {
                                staticClass: "mx-2",
                                style: {
                                  opacity:
                                    _vm.prescriptionStatus.controls.play &&
                                    !_vm.isPlaying
                                      ? "100%"
                                      : "50%",
                                  cursor:
                                    _vm.prescriptionStatus.controls.play &&
                                    !_vm.isPlaying
                                      ? "pointer"
                                      : "not-allowed",
                                  "pointer-events":
                                    _vm.prescriptionStatus.controls.play &&
                                    !_vm.isPlaying
                                      ? "auto"
                                      : "none",
                                },
                                attrs: {
                                  color: "#ffffff",
                                  name: "play",
                                  variant: "",
                                },
                                on: { click: _vm.onPlayPrescription },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [_vm._v(" Pause prescription ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-icon", {
                                staticClass: "mx-2",
                                style: {
                                  opacity:
                                    _vm.prescriptionStatus.controls.pause &&
                                    !_vm.isOneTimePurchase
                                      ? "100%"
                                      : "50%",
                                  cursor:
                                    _vm.prescriptionStatus.controls.pause &&
                                    !_vm.isOneTimePurchase
                                      ? "pointer"
                                      : "not-allowed",
                                  "pointer-events":
                                    _vm.prescriptionStatus.controls.pause &&
                                    !_vm.isOneTimePurchase
                                      ? "auto"
                                      : "none",
                                },
                                attrs: {
                                  color: "#ffffff",
                                  name: "pause",
                                  variant: "",
                                },
                                on: { click: _vm.onPausePrescription },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [_vm._v(" Cancel prescription ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-icon", {
                                staticClass: "mx-2",
                                style: {
                                  opacity:
                                    _vm.prescriptionStatus.controls.stop &&
                                    !_vm.isStopping
                                      ? "100%"
                                      : "50%",
                                  cursor:
                                    _vm.prescriptionStatus.controls.stop &&
                                    !_vm.isStopping
                                      ? "pointer"
                                      : "not-allowed",
                                  "pointer-events":
                                    _vm.prescriptionStatus.controls.stop &&
                                    !_vm.isStopping
                                      ? "auto"
                                      : "none",
                                },
                                attrs: {
                                  color: "#ffffff",
                                  name: "stop",
                                  variant: "",
                                },
                                on: { click: _vm.onStopPrescription },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [_vm._v(" Edit prescription ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.prescription.paymentstatus === undefined &&
                              (_vm.prescriptionStatus.text === "Draft" ||
                                _vm.prescriptionStatus.text === "Scheduled" ||
                                _vm.prescriptionStatus.text === "Waiting")
                                ? _c("v-icon", {
                                    staticClass: "mx-2",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: "#ffffff",
                                      name: "edit",
                                      variant: "",
                                    },
                                    on: { click: _vm.onEditPrescription },
                                  })
                                : ["Prescribed", "Paused"].includes(
                                    _vm.prescriptionStatus.text
                                  ) &&
                                  _vm.prescription.airtablestatus &&
                                  _vm.prescription.airtablestatus !==
                                    "Processing"
                                ? _c("v-icon", {
                                    staticClass: "mx-2",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: "#ffffff",
                                      name: "edit",
                                      variant: "",
                                    },
                                    on: { click: _vm.onEditPrescription },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [_vm._v(" Rush prescription ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.prescriptionStatus.text === "Prescribed" &&
                              _vm.prescription.airtablestatus &&
                              _vm.prescription.airtablestatus === "Processing"
                                ? _c("v-icon", {
                                    staticClass: "mx-2",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: "#ffffff",
                                      name: "forward",
                                      variant: "",
                                    },
                                    on: { click: _vm.onRushDelay },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [_vm._v(" Refill prescription ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.prescriptionStatus.text === "Prescribed" ||
                              _vm.prescriptionStatus.text === "Completed" ||
                              _vm.prescriptionStatus.text === "Paused"
                                ? _c("v-icon", {
                                    staticClass: "mx-2",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: "#ffffff",
                                      name: "refill",
                                      variant: "",
                                    },
                                    on: { click: _vm.onPrescriptionRefills },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "VTooltip",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "popper",
                              fn: function () {
                                return [_vm._v(" Cancel Processing Order ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.prescriptionStatus.text === "Prescribed" &&
                              _vm.prescription.airtablestatus &&
                              _vm.prescription.airtablestatus === "Processing"
                                ? _c("v-icon", {
                                    staticClass: "mx-2",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: "#ffffff",
                                      name: "cancel",
                                      variant: "",
                                    },
                                    on: { click: _vm.onCancelOrder },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _vm.prescriptionStatus.text === "Scheduled"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${
                              _vm.prescriptionStatus.text
                            }: ${_vm.parseUnixDate(
                              _vm.prescription.timestampsubscriptionstartdate,
                              "MM/dd/yy"
                            )}`
                          ) +
                          " "
                      ),
                    ])
                  : _vm.prescriptionStatus.text === "Waiting"
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(`${_vm.prescriptionStatus.text}`) + " "
                      ),
                      _vm.timeLeft
                        ? _c("span", [_vm._v("(" + _vm._s(_vm.timeLeft) + ")")])
                        : _vm._e(),
                    ])
                  : _c("span", [
                      _vm.prescription.resumecollection
                        ? _c(
                            "span",
                            { staticClass: "tw-flex tw-items-center" },
                            [
                              _vm.prescriptionStatus.text === "Failed"
                                ? _c("span", [_vm._v(" Paused until ")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        `${_vm.prescriptionStatus.text} until `
                                      )
                                    ),
                                  ]),
                              _vm.currentDoctor.id
                                ? _c(
                                    "span",
                                    { staticClass: "tw-inline-block tw-ml-1" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.parseUnixDate(
                                              _vm.prescription.resumecollection,
                                              "MM/dd/yy"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "b-dropdown",
                                    {
                                      ref: "pauseduntil-dropdown",
                                      staticClass: "ml-2",
                                      attrs: {
                                        id: "pauseduntil-dropdown",
                                        size: "sm",
                                        split: "",
                                        text: "12/7/21",
                                        right: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function () {
                                              return [
                                                _c("b-form-datepicker", {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    id: "pausedUntil",
                                                    "date-format-options": {
                                                      year: "2-digit",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                    },
                                                    "hide-header": true,
                                                    dark: _vm.isDarkMode,
                                                    "date-disabled-fn":
                                                      _vm.dateDisabled,
                                                    "button-only": "",
                                                    right: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "button-content",
                                                        fn: function () {
                                                          return [
                                                            _c("small", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.parseUnixDate(
                                                                      _vm
                                                                        .prescription
                                                                        .resumecollection,
                                                                      "MM/dd/yy"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    771038381
                                                  ),
                                                  model: {
                                                    value: _vm.pausedUntil,
                                                    callback: function ($$v) {
                                                      _vm.pausedUntil = $$v
                                                    },
                                                    expression: "pausedUntil",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3326509209
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-dropdown-form",
                                        [
                                          _c(
                                            "b-input-group",
                                            {
                                              staticClass: "mb-2",
                                              attrs: {
                                                append: "months from now",
                                                size: "sm",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  min: "1",
                                                  max: "12",
                                                },
                                                model: {
                                                  value: _vm.monthsToAdd,
                                                  callback: function ($$v) {
                                                    _vm.monthsToAdd = $$v
                                                  },
                                                  expression: "monthsToAdd",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "small",
                                                { staticClass: "text-black" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.monthResult)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.savePausedUntilDate,
                                                  },
                                                },
                                                [_vm._v(" Save ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.prescriptionStatus.text) + " "
                            ),
                          ]),
                    ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }