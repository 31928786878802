<template>
  <div class="d-flex flex-column">
    <div class="card-prescription card d-flex flex-column mb-0 tw-border-none">
      <div
        class="card-container-top d-flex flex-column"
        :class="`bg-${statusColor}-light`"
      >
        <div class="card-title">
          <div class="d-flex justify-content-between align-items-start">
            <h1 class="mb-2 h1 pr-2" style="font-size: 22px">
              {{ prescription.medicinename }}
              <span v-if="prescription.dosage"
                >| {{ prescription.dosage }}</span
              >
            </h1>
            <div class="tw-flex tw-flex-col tw-shrink-0">
              <small class="text-muted" style="flex-shrink: 0">
                {{ prescription.unit }}
              </small>
              <div
                class="badge status-processing tw-py-1 tw-rounded-full tw-px-2"
                v-if="prescription.airtablestatus === 'Processing'"
              >
                {{ prescription.airtablestatus }}
              </div>
            </div>
          </div>
          <p
            v-if="!noRefills && prescriptionStatus.text !== 'Draft'"
            :class="`text-${statusColor}`"
          >
            <span
              v-if="
                prescription.paymentstatus === 'FAILED' &&
                prescription.paidrefills === 0 &&
                prescription.refillinput === 0
              "
            >
              No refills
            </span>
            <span
              v-else-if="
                prescription.paymentstatus === 'FAILED' &&
                prescription.paidrefills === 0
              "
            >
              {{ refillsLeft - 1 }} out of
              {{ prescription.refills - 1 }} Refills Left
            </span>
            <span v-else-if="prescription.refills && prescription.paidrefills">
              {{ refillsLeft }} out of {{ prescription.refills - 1 }} Refills
              Left
            </span>
            <span
              v-else-if="
                prescriptionStatus.text === 'Waiting' ||
                prescriptionStatus.text === 'Processing' ||
                prescriptionStatus.text === 'Scheduled'
              "
            >
              Refills: {{ prescription.refillinput }}
            </span>
            <span v-else>No refills yet</span>
          </p>
          <p v-else :class="`text-${statusColor}`">
            <span> Refills: {{ prescription.refillinput }} </span>
          </p>
        </div>
        <div class="tw-grid tw-grid-cols-2">
          <div class="mb-3">
            <p class="mb-0 text-muted info-title">Prescribed</p>
            <p class="mb-0">
              {{
                prescription.timestampprescibed
                  ? parseUnixDate(
                      prescription.timestampprescibed,
                      "MMMM d, yyyy"
                    )
                  : "-"
              }}
              <!-- wrong spelling "prescribed" -->
            </p>
          </div>
          <div>
            <p class="mb-0 text-muted info-title">Treatment Start</p>
            <div class="tw-flex tw-items-center tw-space-x-1">
              <p class="mb-0">
                {{
                  prescription.scheduleddatetimestamp
                    ? parseUnixDate(
                        prescription.scheduleddatetimestamp,
                        "MMMM d, yyyy"
                      )
                    : "-"
                }}
              </p>
              <b-form-datepicker
                v-if="
                  prescription.scheduleddatetimestamp && isBeforeScheduledDate
                "
                v-model="scheduledDate"
                size="sm"
                button-only
                right
                aria-controls="example-input"
                button-variant="primary"
                id="edit-treatmentstart"
                :date-format-options="{
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :hide-header="true"
                :date-disabled-fn="nextShipmentDateDisabled"
              >
                <template #button-content>
                  <b-icon
                    class="tw-cursor-pointer"
                    icon="pencil-square"
                    variant="primary"
                    style="width: 15px; height: 15px"
                  ></b-icon>
                </template>
              </b-form-datepicker>
            </div>
          </div>
          <div>
            <p class="mb-0 text-muted info-title">Next Refill</p>
            <div class="tw-flex tw-items-center tw-space-x-1">
              <p class="mb-0">
                {{
                  prescription.nextshipmentdate
                    ? parseUnixDate(
                        prescription.nextshipmentdate,
                        "MMMM d, yyyy"
                      )
                    : "-"
                }}
              </p>
              <b-form-datepicker
                v-if="prescription.nextshipmentdate"
                v-model="nextShipmentDate"
                size="sm"
                button-only
                right
                aria-controls="example-input"
                button-variant="primary"
                id="edit-refilldate"
                :date-format-options="{
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                }"
                :hide-header="true"
                :date-disabled-fn="nextShipmentDateDisabled"
              >
                <template #button-content>
                  <b-icon
                    class="tw-cursor-pointer"
                    icon="pencil-square"
                    variant="primary"
                    style="width: 15px; height: 15px"
                  ></b-icon>
                </template>
              </b-form-datepicker>
            </div>
          </div>
          <div>
            <p class="mb-0 text-muted info-title">Last Refill Ends</p>
            <p class="mb-0">
              {{
                prescription.actualenddate
                  ? parseUnixDate(prescription.actualenddate, "MMMM d, yyyy")
                  : "-"
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="card-container-bottom text-white"
        :class="`bg-${statusColor}`"
      >
        <div class="d-flex justify-content-between">
          <div v-if="currentDoctor.id"></div>
          <div v-else class="d-flex">
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  :style="{
                    opacity:
                      prescriptionStatus.controls.play && !isPlaying
                        ? '100%'
                        : '50%',
                    cursor:
                      prescriptionStatus.controls.play && !isPlaying
                        ? 'pointer'
                        : 'not-allowed',
                    'pointer-events':
                      prescriptionStatus.controls.play && !isPlaying
                        ? 'auto'
                        : 'none',
                  }"
                  color="#ffffff"
                  name="play"
                  variant=""
                  @click="onPlayPrescription"
                />
              </div>
              <template #popper>
                {{
                  prescriptionStatus.text === "Paused"
                    ? "Resume prescription"
                    : prescriptionStatus.text === "Failed"
                    ? "Retry prescription"
                    : "Play prescription"
                }}
              </template>
            </VTooltip>
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  :style="{
                    opacity:
                      prescriptionStatus.controls.pause && !isOneTimePurchase
                        ? '100%'
                        : '50%',
                    cursor:
                      prescriptionStatus.controls.pause && !isOneTimePurchase
                        ? 'pointer'
                        : 'not-allowed',
                    'pointer-events':
                      prescriptionStatus.controls.pause && !isOneTimePurchase
                        ? 'auto'
                        : 'none',
                  }"
                  color="#ffffff"
                  name="pause"
                  variant=""
                  @click="onPausePrescription"
                />
              </div>
              <template #popper> Pause prescription </template>
            </VTooltip>
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  :style="{
                    opacity:
                      prescriptionStatus.controls.stop && !isStopping
                        ? '100%'
                        : '50%',
                    cursor:
                      prescriptionStatus.controls.stop && !isStopping
                        ? 'pointer'
                        : 'not-allowed',
                    'pointer-events':
                      prescriptionStatus.controls.stop && !isStopping
                        ? 'auto'
                        : 'none',
                  }"
                  color="#ffffff"
                  name="stop"
                  variant=""
                  @click="onStopPrescription"
                />
              </div>
              <template #popper> Cancel prescription </template>
            </VTooltip>
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  v-if="
                    prescription.paymentstatus === undefined &&
                    (prescriptionStatus.text === 'Draft' ||
                      prescriptionStatus.text === 'Scheduled' ||
                      prescriptionStatus.text === 'Waiting')
                  "
                  style="cursor: pointer"
                  color="#ffffff"
                  name="edit"
                  variant=""
                  @click="onEditPrescription"
                />
                <v-icon
                  class="mx-2"
                  v-else-if="
                    ['Prescribed', 'Paused'].includes(
                      prescriptionStatus.text
                    ) &&
                    prescription.airtablestatus &&
                    prescription.airtablestatus !== 'Processing'
                  "
                  style="cursor: pointer"
                  color="#ffffff"
                  name="edit"
                  variant=""
                  @click="onEditPrescription"
                />
              </div>
              <template #popper> Edit prescription </template>
            </VTooltip>
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  v-if="
                    prescriptionStatus.text === 'Prescribed' &&
                    prescription.airtablestatus &&
                    prescription.airtablestatus === 'Processing'
                  "
                  style="cursor: pointer"
                  color="#ffffff"
                  name="forward"
                  variant=""
                  @click="onRushDelay"
                />
              </div>
              <template #popper> Rush prescription </template>
            </VTooltip>
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  v-if="
                    prescriptionStatus.text === 'Prescribed' ||
                    prescriptionStatus.text === 'Completed' ||
                    prescriptionStatus.text === 'Paused'
                  "
                  style="cursor: pointer"
                  color="#ffffff"
                  name="refill"
                  variant=""
                  @click="onPrescriptionRefills"
                />
              </div>
              <template #popper> Refill prescription </template>
            </VTooltip>
            <VTooltip>
              <div>
                <v-icon
                  class="mx-2"
                  v-if="
                    prescriptionStatus.text === 'Prescribed' &&
                    prescription.airtablestatus &&
                    prescription.airtablestatus === 'Processing'
                  "
                  style="cursor: pointer"
                  color="#ffffff"
                  name="cancel"
                  variant=""
                  @click="onCancelOrder"
                />
              </div>
              <template #popper> Cancel Processing Order </template>
            </VTooltip>
          </div>
          <div class="d-flex align-items-center">
            <span v-if="prescriptionStatus.text === 'Scheduled'">
              {{
                `${prescriptionStatus.text}: ${parseUnixDate(
                  prescription.timestampsubscriptionstartdate,
                  "MM/dd/yy"
                )}`
              }}
            </span>
            <span v-else-if="prescriptionStatus.text === 'Waiting'">
              {{ `${prescriptionStatus.text}` }}
              <span v-if="timeLeft">({{ timeLeft }})</span>
            </span>
            <span v-else>
              <span
                v-if="prescription.resumecollection"
                class="tw-flex tw-items-center"
              >
                <span v-if="prescriptionStatus.text === 'Failed'">
                  Paused until
                </span>
                <span v-else>{{ `${prescriptionStatus.text} until ` }}</span>
                <span v-if="currentDoctor.id" class="tw-inline-block tw-ml-1">
                  {{ parseUnixDate(prescription.resumecollection, "MM/dd/yy") }}
                </span>
                <b-dropdown
                  v-else
                  id="pauseduntil-dropdown"
                  ref="pauseduntil-dropdown"
                  size="sm"
                  split
                  text="12/7/21"
                  class="ml-2"
                  right
                >
                  <template #button-content>
                    <b-form-datepicker
                      id="pausedUntil"
                      :date-format-options="{
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      :hide-header="true"
                      v-model="pausedUntil"
                      :dark="isDarkMode"
                      class="ml-2"
                      :date-disabled-fn="dateDisabled"
                      button-only
                      right
                    >
                      <template #button-content>
                        <small>
                          {{
                            parseUnixDate(
                              prescription.resumecollection,
                              "MM/dd/yy"
                            )
                          }}
                        </small>
                      </template>
                    </b-form-datepicker>
                  </template>
                  <b-dropdown-form>
                    <b-input-group
                      append="months from now"
                      size="sm"
                      class="mb-2"
                    >
                      <b-form-input
                        type="number"
                        min="1"
                        max="12"
                        v-model="monthsToAdd"
                      ></b-form-input>
                    </b-input-group>
                    <div class="text-right">
                      <small class="text-black">{{ monthResult }}</small>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="ml-2"
                        @click="savePausedUntilDate"
                      >
                        Save
                      </b-button>
                    </div>
                  </b-dropdown-form>
                </b-dropdown>
              </span>
              <span v-else>
                {{ prescriptionStatus.text }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  format,
  fromUnixTime,
  getUnixTime,
  formatDistanceToNowStrict,
  add,
  sub,
  parse,
  getHours,
  getMinutes,
  getSeconds,
  isBefore,
  differenceInDays,
  startOfDay,
} from "date-fns";
const statusMapper = {
  DRAFTED: {
    text: "Draft",
    controls: {
      play: true,
      pause: false,
      stop: true,
    },
  },
  PROCESSING: {
    text: "Processing",
    controls: {
      play: false,
      pause: false,
      stop: false,
    },
  },
  PRESCRIBED: {
    text: "Prescribed",
    controls: {
      play: false,
      pause: true,
      stop: true,
    },
  },
  SCHEDULED: {
    text: "Scheduled",
    controls: {
      play: false,
      pause: false,
      stop: true,
    },
  },
  FAILED: {
    text: "Failed",
    controls: {
      play: true,
      pause: false,
      stop: true,
    },
  },
  PAUSED: {
    text: "Paused",
    controls: {
      play: true,
      pause: false,
      stop: true,
    },
  },
  COMPLETED: {
    text: "Completed",
    controls: {
      play: true,
      pause: false,
      stop: true,
    },
  },
  WAITING: {
    text: "Waiting",
    controls: {
      play: false,
      pause: true,
      stop: true,
    },
  },
};
export default {
  props: {
    prescription: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPlaying: false,
      timeLeft: "",
      countIntervalFunction: null,
      pausedOption: "Months",
      pausedUntil: "",
      isPausedDateSet: false,
      monthsToAdd: 1,
      isStopping: false,
      nextShipmentDate: fromUnixTime(this.prescription.nextshipmentdate),
      nextShipmentDateCached: null,
      scheduledDate: fromUnixTime(this.prescription.scheduleddatetimestamp),
      calculateTimerFn: null,
      countdownEnded: false,
    };
  },
  computed: {
    prescriptionStatus() {
      return this.isPlaying
        ? statusMapper["PROCESSING"]
        : statusMapper[this.prescription.prescriptionstatus];
    },
    refillsLeft() {
      return (
        this.prescription.refills - 1 - (this.prescription.paidrefills - 1)
      );
    },
    statusColor() {
      if (
        this.prescriptionStatus?.text === "Paused" ||
        this.prescriptionStatus?.text === "Processing" ||
        this.prescriptionStatus?.text === "Waiting"
      ) {
        return "monarch";
      } else if (this.prescriptionStatus.text === "Failed") {
        return "danger";
      } else if (this.prescriptionStatus.text === "Scheduled") {
        return "purple";
      } else {
        return "primary";
      }
    },
    noRefills() {
      return (
        this.prescription.refills === 1 && this.prescription.paidrefills === 1
      );
    },
    refillsExhausted() {
      return this.prescription.refills === this.prescription.paidrefills;
    },
    isDarkMode() {
      return this.$store.state.globals.isDarkMode;
    },
    isOneTimePurchase() {
      return this.prescription.transactiontype === "one_time";
    },
    monthResult() {
      return this.parseUnixDate(
        getUnixTime(
          add(new Date(), {
            months: this.monthsToAdd,
          })
        ),
        "MM/dd/yy"
      );
    },
    isBeforeScheduledDate() {
      if (this.prescription.scheduleddatetimestamp) {
        return isBefore(
          new Date(),
          fromUnixTime(this.prescription.scheduleddatetimestamp)
        );
      } else {
        return false;
      }
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  watch: {
    prescription: {
      handler(val) {
        this.calculateTimeLeft();
      },
      immediate: true,
    },
    pausedUntil(val) {
      this.$emit("prescription-select", this.prescription);
      this.$emit("pause", {
        isIndefinite: false,
        pausedUntilUnix: getUnixTime(
          parse(
            this.getDateStringWithCurrentTime(val),
            "yyyy-MM-dd H:m:s",
            new Date()
          )
        ),
      });
    },
    nextShipmentDate(val) {
      this.$emit("prescription-select", this.prescription);
      this.$emit("update-shipment-date", {
        nextshipmentdate: getUnixTime(
          parse(
            this.getDateStringWithCurrentTime(val),
            "yyyy-MM-dd H:m:s",
            new Date()
          )
        ),
      });
    },
    scheduledDate(val) {
      console.log(val, this.prescription.scheduleddatetimestamp);
      const dayDifference = differenceInDays(
        parse(val, "yyyy-MM-dd", new Date()),
        startOfDay(fromUnixTime(this.prescription.scheduleddatetimestamp))
      );
      this.$emit("prescription-select", this.prescription);
      this.$emit("update-treatment-date", {
        days: dayDifference,
      });
    },
  },
  created() {
    this.nextShipmentDateCached = fromUnixTime(
      this.prescription.nextshipmentdate
    );
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    nextShipmentDateDisabled(ymd, date) {
      return new Date() > date;
    },
    nextScheduledDateDisabled(ymd, date) {
      return sub(this.prescription.scheduleddatetimestamp, { days: 1 }) > date;
    },
    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    },
    showUpdatePrescribeModal() {
      this.$store.dispatch("prescription/togglePrescriptionModal", true);
      this.$store.dispatch(
        "prescription/setSelectedPrescription",
        this.prescription
      );
    },
    showResumePrescribeModal() {
      this.$bvModal.show("resumePrescription");
      this.$emit("prescription-select", this.prescription);
    },
    showRetryPrescribeModal() {
      this.$store.dispatch("prescription/toggleRetryPrescriptionModal", true);
      this.$store.dispatch(
        "prescription/setSelectedPrescription",
        this.prescription
      );
    },
    async onPlayPrescription() {
      if (this.prescriptionStatus.text === "Draft") {
        if (this.prescription.prescriptionversion === "V2") {
          this.isPlaying = true;
          this.$emit("prescription-prescribe", {
            patientid: this.prescription.patientid,
            prescriptionid: this.prescription.id,
            done: () => {
              this.isPlaying = false;
            },
          });
        } else {
          this.showUpdatePrescribeModal();
        }
      } else if (this.prescriptionStatus.text === "Paused") {
        this.showResumePrescribeModal();
      } else if (this.prescriptionStatus.text === "Failed") {
        if (Object.hasOwn(this.prescription, "subscriptionid")) {
          this.showRetryPrescribeModal();
        } else {
          this.isPlaying = true;
          this.$emit("prescription-prescribe", {
            patientid: this.prescription.patientid,
            prescriptionid: this.prescription.id,
            done: () => {
              this.isPlaying = false;
            },
          });
        }
      } else if (this.prescriptionStatus.text === "Completed") {
        this.$store.dispatch(
          "prescription/setSelectedPrescription",
          this.prescription
        );
      }
    },
    async onPausePrescription(payload) {
      if (this.prescriptionStatus.text == "Waiting") {
        this.$bvModal.show("pauseDelayTimer");
        this.$emit("prescription-select", this.prescription);
      } else {
        this.$bvModal.show("pausePrescription");
        this.$emit("prescription-select", this.prescription);
      }
    },
    async onCancelRefund(payload) {
      this.$bvModal.show("cancelRefund");
      this.$emit("prescription-select", this.prescription);
    },
    onPrescriptionRefills() {
      this.$bvModal.show("addRefills");
      this.$emit("prescription-select", this.prescription);
    },
    async onStopPrescription() {
      if (
        this.prescriptionStatus.text === "Draft" ||
        this.prescriptionStatus.text === "Scheduled"
      ) {
        this.$bvModal.show("deletePrescription");
        this.$emit("prescription-select", this.prescription);
      } else if (
        this.prescriptionStatus.text === "Completed" ||
        this.prescriptionStatus.text === "Prescribed" ||
        this.prescriptionStatus.text === "Failed" ||
        this.prescriptionStatus.text === "Paused"
      ) {
        this.onCancelRefund();
      } else if (
        this.prescriptionStatus.text === "Waiting" &&
        this.prescription.paymentstatus !== undefined
      ) {
        this.isStopping = true;
        await this.$store.dispatch("prescription/stopDelay", {
          patientid: this.prescription.patientid,
          prescriptionid: this.prescription.id,
        });
        if (this.countIntervalFunction) {
          clearInterval(this.countIntervalFunction);
          this.countIntervalFunction = null;
        }
        this.isStopping = false;
      } else {
        this.$bvModal.show("cancelPrescription");
        this.$emit("prescription-select", this.prescription);
      }
    },
    async onEditPrescription() {
      if (["Prescribed", "Paused"].includes(this.prescriptionStatus.text)) {
        this.$emit("prescription-select", this.prescription);
        this.$bvModal.show("edit-prescription");
      } else {
        if (this.prescriptionStatus.text !== "Scheduled") {
          await this.$store.dispatch("prescription/stopDelay", {
            patientid: this.prescription.patientid,
            prescriptionid: this.prescription.id,
          });
        }

        this.$router.push(
          `/prescriptions/order/${this.prescription.patientid}/update/${this.prescription.id}`
        );
      }
    },
    distanceToNow(date) {
      return formatDistanceToNowStrict(fromUnixTime(date), { unit: "second" });
    },
    savePausedUntilDate() {
      this.$emit("prescription-select", this.prescription);
      this.$emit("pause", {
        isIndefinite: false,
        pausedUntilUnix: getUnixTime(
          parse(this.monthResult, "MM/dd/yy", new Date())
        ),
      });
      this.$refs["pauseduntil-dropdown"].hide(true);
      this.monthsToAdd = 1;
    },
    async onRushDelay() {
      this.$bvModal.show("rushPrescription");
      this.$emit("prescription-select", this.prescription);
    },
    async onCancelOrder() {
      this.$bvModal.show("cancelOrder");
      this.$emit("prescription-select", this.prescription);
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    calculateTimeLeft() {
      if (this.prescription.prescriptionstatus === "WAITING") {
        if (this.prescription.delayuntil <= getUnixTime(new Date())) {
          this.timeLeft = "0 seconds";
          if (!this.countdownEnded) {
            clearTimeout(this.calculateTimerFn);
            this.$emit("refetch-single-prescription", this.prescription.id);
          }
          this.countdownEnded = true;
        } else {
          this.timeLeft = formatDistanceToNowStrict(
            fromUnixTime(this.prescription.delayuntil),
            { unit: "second" }
          );
          this.calculateTimerFn = setTimeout(this.calculateTimeLeft, 1000);
        }
      }

      if (
        ["PRESCRIBED", "FAILED"].includes(this.prescription.prescriptionstatus)
      ) {
        this.countdownEnded = false;
      }
    },
  },
};
</script>

<style scoped>
/deep/#pause-option {
  display: inline-block;
  width: auto;
  min-width: 80px;
}
/deep/#pause-option .vs__dropdown-toggle {
  height: auto;
  padding: 0;
  border: none;
}
/deep/#pause-option span.vs__selected {
  color: white !important;
}
/deep/#pause-option .vs__selected-options {
  display: flex;
  align-items: center;
}
/deep/#pause-option .vs__open-indicator {
  fill: white;
}
/deep/#pausedUntil.btn {
  padding: 0;
}
/deep/#pausedUntil:focus {
  background: #ff7a37;
  border-color: #ff7a37;
  outline: none;
  box-shadow: none;
}
/deep/#pauseduntil-dropdown__BV_button_:hover #pausedUntil {
  background: #ff6111;
  border-color: #ff6111;
}
/deep/#pausedUntil__dialog_ .b-calendar-grid-help {
  display: none;
}
/deep/#pausedUntil__dialog_ .b-calendar-grid {
  border: none;
}
/deep/#pausedUntil__value_ {
  padding: 0;
  min-height: auto;
  color: white;
}
/deep/#pausedUntil__outer_ {
  width: auto;
}
/deep/#pauseduntil-dropdown > .btn {
  padding: 0 0.25rem;
  line-height: 1.5;
}
/deep/#pauseduntil-dropdown > .dropdown-menu.show {
  width: 200px;
}
/deep/#edit-refilldate,
/deep/#edit-treatmentstart {
  padding: 0;
  background-color: transparent;
  border-color: transparent;
}
</style>

<style lang="scss" scoped>
.card-prescription {
  background-color: $white;
}
.card,
.card-container-top,
.card-title {
  flex: 1 1 auto;
}
.card-container-top {
  padding: 18px;
}
.card-container-bottom {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 18px;
}
.text-monarch {
  color: #ff8d40;
}
.bg-monarch {
  background-color: #ff8d40;
}
.text-purple {
  color: #a783ff;
}
.bg-purple {
  background-color: #a783ff;
}
.info-title {
  font-size: 12px;
}
.bg-primary-light {
  background: rgba(26, 106, 114, 0.025);
}
.bg-monarch-light {
  background: rgba(255, 141, 64, 0.025);
}
.bg-purple-light {
  background: rgba(166, 131, 255, 0.025);
}
.bg-danger-light {
  background: rgba(230, 55, 87, 0.025);
}

.badge {
  &.status-processing {
    background: #61d3e2;
    color: #ccffff;
  }
}
</style>
