var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "resumePrescription",
        "hide-header": "",
        centered: "",
        "hide-footer": "",
        size: "lg",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "no-enforce-focus": "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-justify-end tw-cursor-pointer" },
        [
          _c("b-icon", {
            attrs: {
              icon: "x-circle-fill",
              variant: "primary",
              "font-scale": "1.5",
            },
            on: {
              click: function ($event) {
                return _vm.$bvModal.hide("resumePrescription")
              },
            },
          }),
        ],
        1
      ),
      _c("h1", { staticClass: "heading p-4 mb-0" }, [
        _vm._v("Unpause or Reschedule Prescription"),
      ]),
      _c(
        "b-row",
        { staticClass: "p-4", attrs: { "align-v": "center" } },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _vm._v(
              " Do you want to unpause this prescription or to reschedule? "
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "p-4", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "primary",
                    disabled: _vm.processing,
                  },
                  on: { click: _vm.onPrescriptionReschedule },
                },
                [_vm._v(" Reschedule ")]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "6" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    disabled: _vm.processing,
                    variant: "outline-primary",
                  },
                  on: { click: _vm.onPrescriptionUnpause },
                },
                [
                  _vm.processing
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _c("span", [_vm._v("Unpause")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }