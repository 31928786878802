<template>
  <b-modal
    id="discussPrescription"
    hide-header
    centered
    hide-footer
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
  >
    <h1 class="heading p-4 mb-0">Discuss Patient Prescription</h1>
    <b-row align-v="center" class="p-4">
      <b-col cols="12">
        The patient would like to discuss prior to prescribing.
      </b-col>
    </b-row>

    <b-row align-h="center" class="p-4">
      <b-col cols="6" class="mb-3">
        <b-button
          pill
          block
          @click="$bvModal.hide('discussPrescription')"
          variant="outline-primary"
        >
          <span>Close</span>
        </b-button>
      </b-col>
      <b-col cols="6" class="mb-3">
        <b-button pill block @click="onPrescriptionDiscuss" variant="primary">
          <span>Message Patient</span>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  methods: {
    onPrescriptionDiscuss() {
      this.$emit("discuss");
    },
  },
};
</script>
