var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "pauseDelayTimer",
        "hide-header": "",
        centered: "",
        "hide-footer": "",
        size: "lg",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "no-enforce-focus": "",
      },
      on: { hidden: _vm.onModalHide },
    },
    [
      _c("h1", { staticClass: "heading p-4 mb-0" }, [
        _vm._v("Prescription Timer delay"),
      ]),
      _c(
        "b-row",
        { staticClass: "px-4 pb-4", attrs: { "align-v": "center" } },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _vm._v(" What do you want to do with this prescription? "),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "p-4", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    disabled: _vm.processing,
                    variant: "primary",
                  },
                  on: { click: _vm.onPauseDelay },
                },
                [
                  _vm.processing
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _c("span", [_vm._v("Stop Delay")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: {
                    pill: "",
                    block: "",
                    variant: "outline-primary",
                    disabled: _vm.processing,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("pauseDelayTimer")
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }