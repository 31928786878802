var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "pause-progesterone-alert",
        "hide-header": "",
        "hide-header-close": "",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "lg",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "icon-logo" }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tw-float-right tw-w-full lg:tw-w-4/5" },
        [
          _c("div", [
            _c("h2", { staticClass: "mb-3 heading tw-text-2xl" }, [
              _vm._v(
                " You are pausing Progesterone to a patient while they are prescribed Estrogen. "
              ),
              _c("br"),
              _c("br"),
              _vm._v("Do you want to continue? "),
            ]),
          ]),
          _c(
            "b-row",
            { staticClass: "my-4" },
            [
              _c(
                "b-col",
                { staticClass: "mb-2", attrs: { cols: "8" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        pill: "",
                        variant: "primary",
                        disabled: _vm.processing,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("prescribe")
                        },
                      },
                    },
                    [
                      _vm.processing
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _c("span", [_vm._v("Continue")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        pill: "",
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("pause-progesterone-alert")
                        },
                      },
                    },
                    [_c("span", [_vm._v("Cancel")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }