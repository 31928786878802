var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "addRefills",
            "hide-header": "",
            "hide-header-close": "",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
          },
          on: { shown: _vm.onShow, hidden: _vm.onHide },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" }),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "tw-float-right tw-w-full lg:tw-w-2/3" }, [
            !_vm.isAddRefills
              ? _c("div", [
                  _c("h2", { staticClass: "mb-3 heading tw-text-2xl" }, [
                    _vm._v("Do you want to..."),
                  ]),
                  _c(
                    "div",
                    { staticClass: "tw-mb-6" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { block: "", pill: "", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.show("refillactivation")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Activate Refills")])]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            pill: "",
                            variant: "outline-primary",
                            disabled: _vm.processing,
                          },
                          on: {
                            click: function ($event) {
                              _vm.isAddRefills = true
                            },
                          },
                        },
                        [_c("span", [_vm._v("Add Refills")])]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-primary",
                          attrs: { block: "", pill: "", variant: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("addRefills")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Cancel")])]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isAddRefills
              ? _c(
                  "div",
                  [
                    _c("h2", { staticClass: "mb-3 heading tw-text-2xl" }, [
                      _vm._v(" How many total refills would you like to add? "),
                    ]),
                    _c("ValidationObserver", {
                      ref: "refillsForm",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit }) {
                              return [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tw-mb-6",
                                          attrs: { role: "group" },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "tw-text-primary",
                                              attrs: { for: "reason" },
                                            },
                                            [_vm._v("Patient")]
                                          ),
                                          _c("p", [
                                            _vm._v(_vm._s(_vm.patientName)),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "tw-mb-6",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { attrs: { role: "group" } },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "tw-text-primary",
                                                attrs: { for: "refilltype" },
                                              },
                                              [_vm._v(" Refill Type ")]
                                            ),
                                          ]
                                        ),
                                        _c("v-select", {
                                          staticClass: "refilltype",
                                          attrs: {
                                            placeholder: "Select one",
                                            options: ["One Year", "Other"],
                                          },
                                          model: {
                                            value: _vm.refilltype,
                                            callback: function ($$v) {
                                              _vm.refilltype = $$v
                                            },
                                            expression: "refilltype",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "tw-mb-6",
                                        attrs: { cols: "12" },
                                      },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Refill",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          role: "group",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "tw-text-primary",
                                                            attrs: {
                                                              for: "reason",
                                                            },
                                                          },
                                                          [_vm._v("Refills")]
                                                        ),
                                                      ]
                                                    ),
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        id: "refill",
                                                        type: "number",
                                                        min: "0",
                                                        disabled:
                                                          _vm.refilltype ===
                                                          "One Year",
                                                        readonly:
                                                          _vm.refilltype ===
                                                          "One Year",
                                                        formatter:
                                                          _vm.refillInputFormatter,
                                                      },
                                                      model: {
                                                        value: _vm.refills,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.refills =
                                                            _vm._n($$v)
                                                        },
                                                        expression: "refills",
                                                      },
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(errors[0]) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.refilltype !== "One Year" &&
                                    _vm.followupdatestamp &&
                                    !_vm.followupSentOrAnswered
                                      ? _c(
                                          "b-col",
                                          {
                                            staticClass: "tw-mb-6",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { attrs: { role: "group" } },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "tw-text-primary",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Current Follow Up Date"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.parseDate(
                                                      _vm.followupdatestamp,
                                                      "MM-dd-yyyy"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.refilltype !== "One Year" &&
                                    !_vm.followupSentOrAnswered
                                      ? _c(
                                          "b-col",
                                          {
                                            staticClass: "tw-mb-6",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "Keep Followup",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "b-form-group",
                                                          {
                                                            staticClass: "mb-0",
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-primary",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Would you like to keep this follow-up appointment? "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-radio-group",
                                                              {
                                                                attrs: {
                                                                  options: [
                                                                    {
                                                                      text: "Yes",
                                                                      value: true,
                                                                    },
                                                                    {
                                                                      text: "No",
                                                                      value: false,
                                                                    },
                                                                  ],
                                                                  name: "radio-inline",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.keepFollowup,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.keepFollowup =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "keepFollowup",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  errors[0]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    (!_vm.keepFollowup ||
                                      _vm.followupSentOrAnswered) &&
                                    _vm.refilltype !== "One Year"
                                      ? _c(
                                          "b-col",
                                          {
                                            staticClass: "tw-mb-6",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c("ValidationProvider", {
                                              attrs: {
                                                name: "New Follow Up Date",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "tw-text-primary",
                                                            attrs: {
                                                              for: "newfollowupdate",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " New Follow Up Date "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-form-datepicker",
                                                          {
                                                            attrs: {
                                                              id: "newfollowupdate",
                                                              "date-disabled-fn":
                                                                _vm.dateDisabled,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newfollowupdate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.newfollowupdate =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "newfollowupdate",
                                                            },
                                                          }
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  errors[0]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-6",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _vm.prescription.refills ===
                                          _vm.prescription.paidrefills ||
                                        _vm.isBeforeScheduledDate
                                          ? _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  block: "",
                                                  pill: "",
                                                  variant: "outline-primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$bvModal.hide(
                                                      "addRefills"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Cancel ")]
                                            )
                                          : _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  block: "",
                                                  pill: "",
                                                  variant: "outline-primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.isAddRefills = false
                                                  },
                                                },
                                              },
                                              [_vm._v(" Back ")]
                                            ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              block: "",
                                              pill: "",
                                              variant: "primary",
                                              disabled: _vm.processing,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return handleSubmit(
                                                  _vm.onRefill
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.processing
                                              ? _c("b-spinner", {
                                                  attrs: { small: "" },
                                                })
                                              : _c("span", [_vm._v("Refill")]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2790168134
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "refillactivation",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "md",
          },
        },
        [
          _c(
            "b-row",
            { staticStyle: { position: "relative" } },
            [
              _c(
                "b-col",
                {
                  staticStyle: { position: "absolute", top: "0", left: "0" },
                  attrs: { cols: "12", sm: "5" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "30",
                        height: "27",
                        viewBox: "0 0 30 27",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                          fill: "#1A6A72",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "py-6",
                  attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "heading mb-4",
                      staticStyle: { "line-height": "30px" },
                    },
                    [_vm._v(" Refill Activation ")]
                  ),
                  _c("p", { staticClass: "mb-4" }, [
                    _vm._v("Are you sure you want to activate a refill?"),
                  ]),
                  _c(
                    "b-row",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                variant: "outline-primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("refillactivation")
                                },
                              },
                            },
                            [_vm._v(" Nevermind ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                pill: "",
                                block: "",
                                disabled: _vm.processing,
                                variant: "primary",
                              },
                              on: { click: _vm.onActivateRefills },
                            },
                            [
                              _vm.processing
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _c("span", [_vm._v("Confirm")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }