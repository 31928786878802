var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "edit-prescription",
        "hide-header": "",
        "hide-header-close": "",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "xl",
      },
      on: { hide: _vm.onHide, shown: _vm.onShown },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "icon-logo" }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tw-w-full" },
        [
          _c("div", [
            _c("h2", { staticClass: "mb-3 heading tw-text-2xl" }, [
              _vm._v("Edit prescription"),
            ]),
          ]),
          _vm.isProcessing && !_vm.isLoaded
            ? _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("b-spinner", { attrs: { variant: "primary" } }),
                      _c("h2", [_vm._v("Fetching dosages...")]),
                      _c(
                        "b-button",
                        {
                          attrs: { pill: "", variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("edit-prescription")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Nevermind")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h4", { staticClass: "mr-4 text-primary" }, [
                        _vm._v("Prescription Order"),
                      ]),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.alert.show,
                            variant: _vm.alert.type,
                          },
                        },
                        [_vm._v(_vm._s(_vm.alert.message))]
                      ),
                      _c(
                        "b-card",
                        {
                          staticClass: "prescription-details",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { for: "subscription_startdate" },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v("Subscription Start Date"),
                                      ]),
                                    ]
                                  ),
                                  _c("b-form-datepicker", {
                                    attrs: {
                                      id: "subscription_startdate",
                                      "date-disabled-fn":
                                        _vm.subscriptionStartDateDisabled,
                                    },
                                    model: {
                                      value: _vm.subscriptionstartdate,
                                      callback: function ($$v) {
                                        _vm.subscriptionstartdate = $$v
                                      },
                                      expression: "subscriptionstartdate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-card",
                        { staticClass: "update-order" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-grid tw-grid-cols-10 tw-mb-4 tw-space-y-4 lg:tw-space-y-0 md:tw-space-x-4 tw-place-items-stretch",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-4",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { for: "prescription" },
                                    },
                                    [_c("small", [_vm._v("Prescription")])]
                                  ),
                                  _c("v-select", {
                                    staticClass: "prescription-select",
                                    attrs: {
                                      id: "prescription",
                                      "calculate-position": _vm.withPopper,
                                      label: "text",
                                      options: _vm.prescriptions,
                                      reduce: (prescriptions) =>
                                        prescriptions.value,
                                      disabled: true,
                                      placeholder: "Fetching data...",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onSelectPrescription(
                                          _vm.prescription
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "open-indicator",
                                        fn: function ({ attributes }) {
                                          return [
                                            _c(
                                              "b-icon",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "chevron-down",
                                                    variant: "primary",
                                                  },
                                                },
                                                "b-icon",
                                                attributes,
                                                false
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.prescription.productid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prescription,
                                          "productid",
                                          $$v
                                        )
                                      },
                                      expression: "prescription.productid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-2",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { for: "dosage" },
                                    },
                                    [_c("small", [_vm._v("Dosage")])]
                                  ),
                                  _c("v-select", {
                                    staticClass: "prescription-select",
                                    attrs: {
                                      id: "dosage",
                                      "calculate-position": _vm.withPopper,
                                      label: "text",
                                      options:
                                        _vm.dosages[_vm.productid] &&
                                        _vm.dosages[_vm.productid].filter(
                                          ({ value }) => value !== ""
                                        ),
                                      reduce: (dosages) => dosages.value,
                                      disabled: !_vm.prescription.productid,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.changeDosage(
                                          _vm.prescription
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "open-indicator",
                                        fn: function ({ attributes }) {
                                          return [
                                            _c(
                                              "b-icon",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "chevron-down",
                                                    variant: "primary",
                                                  },
                                                },
                                                "b-icon",
                                                attributes,
                                                false
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.prescription.dosage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prescription,
                                          "dosage",
                                          $$v
                                        )
                                      },
                                      expression: "prescription.dosage",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-3",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { for: "unit" },
                                    },
                                    [_c("small", [_vm._v("Unit")])]
                                  ),
                                  _c("v-select", {
                                    staticClass: "prescription-select",
                                    attrs: {
                                      id: "unit",
                                      "calculate-position": _vm.withPopper,
                                      label: "text",
                                      options:
                                        _vm.units[_vm.productid] &&
                                        _vm.units[_vm.productid].filter(
                                          (unit) =>
                                            unit.dosage ===
                                            _vm.prescription.dosage
                                        ),
                                      reduce: (units) => units.value,
                                      clearable: "",
                                      disabled: !_vm.prescription.dosage,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.selectedUnit(
                                          _vm.productid,
                                          _vm.prescription.pricingid
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "open-indicator",
                                        fn: function ({ attributes }) {
                                          return [
                                            _c(
                                              "b-icon",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    icon: "chevron-down",
                                                    variant: "primary",
                                                  },
                                                },
                                                "b-icon",
                                                attributes,
                                                false
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.prescription.pricingid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prescription,
                                          "pricingid",
                                          $$v
                                        )
                                      },
                                      expression: "prescription.pricingid",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-1",
                                  attrs: { role: "group" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-primary",
                                      attrs: { for: "unit" },
                                    },
                                    [_c("small", [_vm._v("Refill")])]
                                  ),
                                  _c("b-form-input", {
                                    attrs: { id: "refill", type: "number" },
                                    model: {
                                      value: _vm.prescription.refillinput,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prescription,
                                          "refillinput",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "prescription.refillinput",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  { attrs: { role: "group" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "text-primary",
                                        attrs: { for: "textarea" },
                                      },
                                      [_c("small", [_vm._v("Doctor Notes")])]
                                    ),
                                    _c("b-form-textarea", {
                                      staticClass: "pl-0",
                                      staticStyle: { overflow: "auto" },
                                      attrs: {
                                        id: "textarea",
                                        rows: "3",
                                        "max-rows": "6",
                                      },
                                      model: {
                                        value: _vm.prescription.notes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.prescription,
                                            "notes",
                                            $$v
                                          )
                                        },
                                        expression: "prescription.notes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _vm.isLoaded
            ? _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            pill: "",
                            variant: "outline-primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("edit-prescription")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Nevermind")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-2", attrs: { cols: "6" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            pill: "",
                            variant: "primary",
                            disabled: _vm.isProcessing,
                          },
                          on: { click: () => _vm.onEditPrescription(false) },
                        },
                        [
                          _vm.isProcessing
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _c("span", [_vm._v("Save")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }