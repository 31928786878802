var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "cancelRefund",
        "hide-header": "",
        "hide-header-close": "",
        centered: "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "lg",
      },
      on: { hide: _vm.onHide },
    },
    [
      _c(
        "b-row",
        { staticClass: "mb-6" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "icon-logo" }),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-card" },
        [
          _c("h2", { staticClass: "mb-3 heading tw-text-2xl" }, [
            _vm._v(
              " Are you sure you want to cancel" +
                _vm._s(_vm.isBeforeScheduledDate ? " & refund " : " ") +
                "this prescription? "
            ),
          ]),
          _c("ValidationObserver", {
            ref: "notesForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "tw-mb-6",
                              attrs: { role: "group" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "tw-text-primary",
                                  attrs: { for: "reason" },
                                },
                                [_vm._v("Patient")]
                              ),
                              _c("p", [_vm._v(_vm._s(_vm.patientName))]),
                            ]
                          ),
                        ]),
                        !_vm.isBeforeScheduledDate &&
                        _vm.prescriptionstatus !== "FAILED"
                          ? _c(
                              "b-col",
                              { staticClass: "tw-mb-6", attrs: { cols: "12" } },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Refund", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-primary",
                                                            },
                                                            [_vm._v("Refund")]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("b-form-radio-group", {
                                                  attrs: {
                                                    options: [
                                                      {
                                                        text: "Yes",
                                                        value: true,
                                                      },
                                                      {
                                                        text: "No",
                                                        value: false,
                                                      },
                                                    ],
                                                    name: "radio-inline",
                                                  },
                                                  model: {
                                                    value: _vm.isRefund,
                                                    callback: function ($$v) {
                                                      _vm.isRefund = $$v
                                                    },
                                                    expression: "isRefund",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(errors[0]) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-col",
                          { staticClass: "tw-mb-6", attrs: { cols: "12" } },
                          [
                            _c(
                              "div",
                              { attrs: { role: "group" } },
                              [
                                _c("ValidationProvider", {
                                  attrs: { name: "Reason", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "tw-text-primary",
                                                attrs: { for: "reason" },
                                              },
                                              [_vm._v("Reason")]
                                            ),
                                            _c("v-select", {
                                              staticClass: "reason",
                                              attrs: {
                                                id: "reason",
                                                "calculate-position":
                                                  _vm.withPopper,
                                                disabled: _vm.processing,
                                                label: "text",
                                                options: _vm.options,
                                                searchable: false,
                                                maxHeight: "200px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "open-indicator",
                                                    fn: function ({
                                                      attributes,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-icon",
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: "chevron-down",
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            "b-icon",
                                                            attributes,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.reason,
                                                callback: function ($$v) {
                                                  _vm.reason = $$v
                                                },
                                                expression: "reason",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(errors[0]) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "tw-mb-6",
                              attrs: { role: "group" },
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "tw-text-primary",
                                  attrs: { for: "reason" },
                                },
                                [_vm._v("Notes")]
                              ),
                              _c("b-form-textarea", {
                                staticClass: "tw-p-0",
                                attrs: {
                                  placeholder: "Select One",
                                  rows: "5",
                                  "no-resize": "",
                                },
                                model: {
                                  value: _vm.cancellationnotes,
                                  callback: function ($$v) {
                                    _vm.cancellationnotes = $$v
                                  },
                                  expression: "cancellationnotes",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  block: "",
                                  pill: "",
                                  variant: "outline-primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$bvModal.hide("cancelRefund")
                                  },
                                },
                              },
                              [_vm._v(" Back ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "6" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  block: "",
                                  pill: "",
                                  variant: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return handleSubmit(_vm.sendToAdmin)
                                  },
                                },
                              },
                              [
                                _vm.processing
                                  ? _c("b-spinner", { attrs: { small: "" } })
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.isBeforeScheduledDate &&
                                            _vm.prescriptionstatus !== "FAILED"
                                            ? "Send To Admin"
                                            : "Confirm"
                                        )
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }