<template>
  <b-modal
    id="pausePrescription"
    hide-header
    centered
    hide-footer
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    @shown="onShow"
    @hidden="onModalHide"
  >
    <h1 class="heading p-4 mb-0">Pause Prescription</h1>
    <b-row align-v="center" class="px-4 pb-4">
      <b-col cols="12">
        Are you sure you want to pause this Prescription?
      </b-col>
    </b-row>

    <b-row align-v="center" class="px-4">
      <b-col cols="12">
        <b-form-radio
          class="mb-2"
          v-model="isIndefinite"
          name="is-indefinite-radio"
          :value="true"
          size="lg"
        >
          Indefinite
        </b-form-radio>
        <b-form-radio
          v-model="isIndefinite"
          name="is-indefinite-radio"
          :value="false"
          size="lg"
        >
          Set a date
        </b-form-radio>
      </b-col>
    </b-row>

    <b-row class="px-4 pt-2 pb-4" v-if="!isIndefinite">
      <b-col cols="6">
        <b-form-datepicker
          id="paused-until"
          v-model="pausedUntil"
          :date-disabled-fn="dateDisabled"
        ></b-form-datepicker>
        <small v-if="hasError" class="text-danger">Please select a date</small>
      </b-col>
    </b-row>

    <b-row align-h="center" class="p-4">
      <b-col cols="6">
        <b-button
          pill
          block
          @click="$bvModal.hide('pausePrescription')"
          variant="primary"
          :disabled="processing"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col cols="6" class="mb-3">
        <b-button
          pill
          block
          :disabled="processing"
          @click="onPausePrescription"
          variant="outline-primary"
        >
          <b-spinner v-if="processing" small></b-spinner>
          <span v-else>Pause</span>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  format,
  fromUnixTime,
  getUnixTime,
  parse,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    prescription: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isIndefinite: true,
      pausedUntil: null,
      hasError: false,
    };
  },
  watch: {
    pausedUntil(val) {
      if (val) {
        this.hasError = false;
      }
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    onPausePrescription() {
      if (!this.pausedUntil && !this.isIndefinite) {
        this.hasError = true;
        return;
      }

      this.$emit("pause", {
        isIndefinite: this.isIndefinite,
        pausedUntilUnix: getUnixTime(
          parse(
            this.getDateStringWithCurrentTime(this.pausedUntil),
            "yyyy-MM-dd H:m:s",
            new Date()
          )
        ),
      });
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    onModalHide() {
      this.resetState();
    },
    resetState() {
      this.isIndefinite = true;
      this.pausedUntil = null;
    },
    onShow() {
      if (this.prescription?.resumecollection) {
        this.isIndefinite = false;
        this.pausedUntil = this.parseUnixDate(
          this.prescription.resumecollection,
          "yyyy-MM-dd"
        );
      }
    },
    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    },
  },
};
</script>
