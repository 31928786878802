<template>
  <b-modal
    id="cancelRefund"
    hide-header
    hide-header-close
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    @hide="onHide"
  >
    <b-row class="mb-6">
      <b-col cols="12">
        <div class="icon-logo"></div>
      </b-col>
    </b-row>
    <div class="modal-card">
      <h2 class="mb-3 heading tw-text-2xl">
        Are you sure you want to cancel{{
          isBeforeScheduledDate ? " & refund " : " "
        }}this prescription?
      </h2>
      <ValidationObserver ref="notesForm" v-slot="{ handleSubmit }">
        <b-row>
          <b-col cols="12">
            <div role="group" class="tw-mb-6">
              <label for="reason" class="tw-text-primary">Patient</label>
              <p>{{ patientName }}</p>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="tw-mb-6"
            v-if="!isBeforeScheduledDate && prescriptionstatus !== 'FAILED'"
          >
            <ValidationProvider
              name="Refund"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group>
                <template #label>
                  <span class="text-primary">Refund</span>
                </template>
                <b-form-radio-group
                  v-model="isRefund"
                  :options="[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>
              <small class="text-danger">
                {{ errors[0] }}
              </small>
            </ValidationProvider>
          </b-col>
          <b-col cols="12" class="tw-mb-6">
            <div role="group">
              <ValidationProvider
                name="Reason"
                rules="required"
                v-slot="{ errors }"
              >
                <label for="reason" class="tw-text-primary">Reason</label>
                <v-select
                  id="reason"
                  class="reason"
                  :calculate-position="withPopper"
                  :disabled="processing"
                  v-model="reason"
                  label="text"
                  :options="options"
                  :searchable="false"
                  maxHeight="200px"
                >
                  <template #open-indicator="{ attributes }">
                    <b-icon
                      v-bind="attributes"
                      icon="chevron-down"
                      variant="primary"
                    ></b-icon>
                  </template>
                </v-select>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </ValidationProvider>
            </div>
          </b-col>
          <!-- <b-col cols="12" v-if="reason.value == 'other'">
          <div role="group" class="tw-mb-6">
            <label for="reason" class="tw-text-primary">Other Reason</label>
            <b-form-input
              v-model="otherReason"
              placeholder="Other Reason"
              class="tw-p-0"
            ></b-form-input>
          </div>
        </b-col> -->
          <b-col cols="12">
            <div role="group" class="tw-mb-6">
              <label for="reason" class="tw-text-primary">Notes</label>
              <b-form-textarea
                placeholder="Select One"
                v-model="cancellationnotes"
                rows="5"
                no-resize
                class="tw-p-0"
              >
              </b-form-textarea>
            </div>
          </b-col>
          <b-col cols="6">
            <b-button
              block
              pill
              variant="outline-primary"
              @click="$bvModal.hide('cancelRefund')"
            >
              Back
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              block
              pill
              variant="secondary"
              @click="handleSubmit(sendToAdmin)"
            >
              <b-spinner v-if="processing" small></b-spinner>
              <span v-else>{{
                !isBeforeScheduledDate && prescriptionstatus !== "FAILED"
                  ? "Send To Admin"
                  : "Confirm"
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import popper from "@/assets/js/popper.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { isBefore, fromUnixTime } from "date-fns";
export default {
  mixins: [popper],
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    patientName: {
      type: String,
      default: "",
    },
    prescriptionstatus: {
      type: String,
      default: "",
    },
    scheduleddatetimestamp: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      reason: "",
      otherReason: "",
      cancellationnotes: "",
      options: [
        { value: "change_mind", text: "Patient changed their mind" },
        { value: "change_dose", text: "Dose changed" },
        { value: "change_prescription", text: "Prescription changed" },
        { value: "side_effect", text: "Side effects" },
        { value: "other", text: "Other" },
      ],
      isRefund: null,
    };
  },
  computed: {
    isBeforeScheduledDate() {
      if (this.scheduleddatetimestamp) {
        return isBefore(new Date(), fromUnixTime(this.scheduleddatetimestamp));
      } else {
        return false;
      }
    },
  },
  methods: {
    sendToAdmin() {
      if (this.prescriptionstatus === "FAILED") {
        this.$emit("cancel-refund", {
          cancellationnotes: this.cancellationnotes,
          cancellationreason: this.reason.value,
        });
        return;
      }

      if (this.isBeforeScheduledDate) {
        this.$emit("cancel-refund", {
          cancellationnotes: this.cancellationnotes,
          cancellationreason: this.reason.value,
          refund: true,
        });
      } else {
        this.$emit("cancel-refund", {
          cancellationnotes: this.cancellationnotes,
          cancellationreason: this.reason.value,
          refund: this.isRefund,
        });
      }
    },
    onHide() {
      this.reason = "";
      this.cancellationnotes = "";
      this.isRefund = null;
    },
  },
};
</script>

<style>
.reason .vs__dropdown-menu {
  max-height: 200px !important;
}
</style>
