var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.items.length > 0,
          expression: "items.length > 0",
        },
      ],
      staticClass: "pr-0 mb-5",
      class: `${
        _vm.$route.name === "treatments"
          ? "section-card-container-treatment"
          : "section-card-container-profile"
      }`,
    },
    [
      _c(
        "div",
        { staticClass: "tw-grid tw-grid-cols-1 xl:tw-grid-cols-3 tw-gap-3" },
        _vm._l(_vm.items, function (item) {
          return _c("card-prescription", {
            key: item.id,
            attrs: { prescription: item },
            on: {
              "prescription-select": _vm.onPrescriptionSelect,
              "prescription-prescribe": _vm.onPrescriptionPrescribe,
              pause: (payload) => _vm.onPause(payload, false),
              "rush-delay": _vm.onRushDelay,
              "rush-delay-timer": _vm.onRushDelayTimer,
              "update-shipment-date": _vm.onUpdateShipmentDate,
              "update-treatment-date": _vm.onUpdateTreatmentDate,
              "refetch-single-prescription": _vm.cardRefetchSinglePrescription,
            },
          })
        }),
        1
      ),
      _c("modal-pause-prescription", {
        attrs: {
          processing: _vm.isProcessing,
          prescription: _vm.selectedPrescription,
        },
        on: { pause: (payload) => _vm.onPause(payload, false) },
      }),
      _c("modal-delete-prescription", {
        attrs: { processing: _vm.isProcessing },
        on: { delete: _vm.onDelete },
      }),
      _c("modal-rush-prescription", {
        attrs: { processing: _vm.isProcessing },
        on: { rush: _vm.onRush },
      }),
      _c("modal-cancel-order", {
        attrs: { processing: _vm.isProcessing },
        on: { "cancel-order": _vm.onCancelOrder },
      }),
      _c("modal-resume-prescription", {
        attrs: { processing: _vm.isProcessing },
        on: { resume: _vm.onResume, reschedule: _vm.onReschedule },
      }),
      _c("modal-cancel-prescription", {
        attrs: { processing: _vm.isProcessing },
        on: { cancel: _vm.onCancel },
      }),
      _c("modal-cancel-refund", {
        attrs: {
          processing: _vm.isProcessing,
          "patient-name": _vm.selectedPrescription.name,
          scheduleddatetimestamp:
            _vm.selectedPrescription.scheduleddatetimestamp,
          prescriptionstatus: _vm.selectedPrescription.prescriptionstatus,
        },
        on: { "cancel-refund": _vm.onCancelRefund },
      }),
      _c("modal-add-refills", {
        attrs: {
          "patient-name": _vm.selectedPrescription.name,
          scheduleddatetimestamp:
            _vm.selectedPrescription.scheduleddatetimestamp,
          followupdatestamp: _vm.patientInfo.followupdatestamp,
          subscriptiontype: _vm.selectedPrescription.subscriptiontype,
          processing: _vm.isProcessing,
          prescription: _vm.selectedPrescription,
        },
        on: {
          "activate-refills": _vm.onActivateRefills,
          refill: (payload) => _vm.onRefill(payload, false),
          "hide-add-refills": () =>
            _vm.$emit("update:isAddRefillModalOpen", false),
        },
      }),
      _c("ModalPrescribeEstrogenAlert", {
        attrs: {
          id: "prescribe-estrogen-refill-alert",
          processing: _vm.isProcessing,
        },
        on: {
          prescribe: (payload) => _vm.onRefill(_vm.addRefillPayload, true),
        },
      }),
      _c("ModalPrescribeEstrogenAlert", {
        attrs: {
          id: "prescribe-estrogen-pause-alert",
          processing: _vm.isProcessing,
        },
        on: { prescribe: () => _vm.onResume(true) },
      }),
      _c("ModalPrescribeEstrogenAlert", {
        attrs: {
          id: "prescribe-estrogen-edit-alert",
          processing: _vm.isProcessing,
        },
        on: {
          prescribe: () =>
            _vm.$refs.editPrescriptionModal.onEditPrescription(true),
        },
      }),
      _c("ModalPauseProgesteroneAlert", {
        attrs: { processing: _vm.isProcessing },
        on: { prescribe: (payload) => _vm.onPause(_vm.pausePayload, true) },
      }),
      _c("ModalPauseDelayTimer", {
        attrs: { processing: _vm.isProcessing },
        on: {
          "rush-delay-timer": _vm.onRushDelayTimer,
          "stop-delay": _vm.stopDelayTimer,
        },
      }),
      _c("ModalInAddition", {
        attrs: {
          prescriptions: _vm.inAdditionPrescriptions,
          processing: _vm.isProcessing,
        },
        on: { "in-addition": (payload) => _vm.onRefill(null, true) },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "duplicate-prescription",
            "hide-header": "",
            "hide-header-close": "",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "md",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-4" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "icon-logo" }),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tw-float-right tw-w-full lg:tw-w-4/5" },
            [
              _c("div", [
                _c("h2", { staticClass: "mb-4 heading tw-text-2xl" }, [
                  _vm._v(
                    " The patient already has a prescription or has a duplicate entry for this medication. "
                  ),
                ]),
              ]),
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { block: "", pill: "", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.hide("duplicate-prescription")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Close")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalEditPrescription", {
        ref: "editPrescriptionModal",
        attrs: { "selected-prescription": _vm.selectedPrescription },
        on: { edit: _vm.onEditPrescription },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }