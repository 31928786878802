var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      staticClass: "mb-0 table-card",
      staticStyle: { border: "0" },
      attrs: { "header-tag": "header", "no-body": "" },
      scopedSlots: _vm._u(
        [
          !_vm.noHeader
            ? {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "b-row",
                      { attrs: { "align-v": "center" } },
                      [
                        _vm.title
                          ? _c(
                              "b-col",
                              { attrs: { cols: _vm.hasSearch ? "auto" : "" } },
                              [_c("strong", [_vm._v(_vm._s(_vm.title))])]
                            )
                          : _vm._e(),
                        _vm.hasSearch
                          ? _c("b-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group input-group-flush",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-prepend" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("i", {
                                            staticClass: "fe fe-search",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filter,
                                        expression: "filter",
                                      },
                                    ],
                                    staticClass: "form-control list-search",
                                    attrs: {
                                      type: "search",
                                      placeholder: "Search",
                                    },
                                    domProps: { value: _vm.filter },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.filter = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "auto" } },
                          [
                            _c(
                              "b-row",
                              { attrs: { "align-v": "center" } },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _vm.filter
                                      ? [
                                          _vm._v("Showing "),
                                          _c("b", [_vm._v(_vm._s(_vm.rows))]),
                                          _vm.rows == 1 || _vm.rows == 0
                                            ? [_vm._v(" result")]
                                            : [_vm._v(" results")],
                                          _vm._v(
                                            " for “" + _vm._s(_vm.filter) + "”"
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c("b-table", {
        ref: "selectableTable",
        class: ["table-nowrap billing-table", _vm.className],
        attrs: {
          responsive: "",
          "select-mode": "single",
          selectable: _vm.isSelectable,
          "sticky-header": _vm.scrollable ? _vm.scrollHeight : false,
          fields: _vm.fields,
          items: _vm.items,
          filter: _vm.hasSearch ? _vm.filter : "",
          "current-page": _vm.hasPagination ? _vm.currentPage : "",
          "per-page": _vm.hasPagination ? _vm.itemPerPage : "",
        },
        on: { filtered: _vm.onFiltered, "row-selected": _vm.onRowSelected },
        nativeOn: {
          scroll: function ($event) {
            return _vm.onScroll.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "cell(type)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(data.item.type === "1YEAR" ? "1 Year" : "10 Week") +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(action)",
            fn: function (data) {
              return [
                data.item.followupstatus === "SCHEDULED"
                  ? _c(
                      "a",
                      {
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openFollowupModal({
                              patientid: data.item.patientid,
                              patientfollowupid: data.item.id,
                              followupdatestamp: data.item.followupdatestamp,
                            })
                          },
                        },
                      },
                      [_vm._v(" Update ")]
                    )
                  : _vm._e(),
                (_vm.items.length > 1 &&
                  data.item.followupstatus === "SCHEDULED" &&
                  _vm.scheduledCount > 1) ||
                data.item.followupstatus === "SENT"
                  ? _c(
                      "a",
                      {
                        staticClass: "pl-3",
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.openFollowupDeleteModal({
                              patientid: data.item.patientid,
                              patientfollowupid: data.item.id,
                              followupdatestamp: data.item.followupdatestamp,
                            })
                          },
                        },
                      },
                      [_vm._v(" Delete ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "followupupdate",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c("ValidationObserver", {
            ref: "followupDeleteForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "b-row",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "b-col",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              left: "0",
                            },
                            attrs: { cols: "12", sm: "5" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "30",
                                  height: "27",
                                  viewBox: "0 0 30 27",
                                  fill: "#1A6A72",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                                    fill: "#1A6A72",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "py-6",
                            attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass: "heading mb-4",
                                staticStyle: { "line-height": "30px" },
                              },
                              [_vm._v(" Update follow-up date ")]
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "mb-4",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "followup",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "text-primary",
                                                    attrs: { for: "followup" },
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "Schedule Follow-up"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  attrs: {
                                                    id: "followup",
                                                    "calculate-position":
                                                      _vm.withPopper,
                                                    label: "text",
                                                    options:
                                                      _vm.followUpOptions,
                                                    reduce: (followUpOptions) =>
                                                      followUpOptions.value,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "open-indicator",
                                                        fn: function ({
                                                          attributes,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "b-icon",
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    icon: "chevron-down",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                },
                                                                "b-icon",
                                                                attributes,
                                                                false
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.selectedFollowUpOption,
                                                    callback: function ($$v) {
                                                      _vm.selectedFollowUpOption =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedFollowUpOption",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "followup_date",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "text-primary",
                                                    attrs: {
                                                      for: "followup_date",
                                                    },
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v("Follow-up Date"),
                                                    ]),
                                                  ]
                                                ),
                                                _c("b-form-datepicker", {
                                                  attrs: {
                                                    id: "followup_date",
                                                    disabled:
                                                      _vm.selectedFollowUpOption !==
                                                      "Manual",
                                                    "date-disabled-fn":
                                                      _vm.dateDisabled,
                                                  },
                                                  model: {
                                                    value: _vm.followupDate,
                                                    callback: function ($$v) {
                                                      _vm.followupDate = $$v
                                                    },
                                                    expression: "followupDate",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          variant: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$bvModal.hide(
                                              "followupupdate"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          disabled: _vm.isProcessing,
                                          variant: "outline-primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(
                                              _vm.onFollowupUpdate
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.isProcessing
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _c("span", [_vm._v("Confirm")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "followupdelete",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c("ValidationObserver", {
            ref: "followupForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "b-row",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "b-col",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              left: "0",
                            },
                            attrs: { cols: "12", sm: "5" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "30",
                                  height: "27",
                                  viewBox: "0 0 30 27",
                                  fill: "#1A6A72",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                                    fill: "#1A6A72",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "py-6",
                            attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass: "heading mb-4",
                                staticStyle: { "line-height": "30px" },
                              },
                              [_vm._v(" Delete follow-up date ")]
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-4" },
                              [
                                _c("b-col", { attrs: { cols: "12" } }, [
                                  _vm._v(" " + _vm._s(_vm.followupDate) + " "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          variant: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$bvModal.hide(
                                              "followupdelete"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          disabled: _vm.isProcessing,
                                          variant: "outline-primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(
                                              _vm.onFollowupDelete
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.isProcessing
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _c("span", [_vm._v("Confirm")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "followupschedule",
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:schedule-followup", false)
            },
          },
        },
        [
          _c("ValidationObserver", {
            ref: "followupScheduleForm",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "b-row",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "b-col",
                          {
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              left: "0",
                            },
                            attrs: { cols: "12", sm: "5" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "30",
                                  height: "27",
                                  viewBox: "0 0 30 27",
                                  fill: "#1A6A72",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                                    fill: "#1A6A72",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "py-6",
                            attrs: { cols: "12", sm: "8", "offset-sm": "2" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass: "heading mb-4",
                                staticStyle: { "line-height": "30px" },
                              },
                              [_vm._v(" Schedule a follow-up ")]
                            ),
                            _c(
                              "b-alert",
                              {
                                attrs: {
                                  show: _vm.scheduleAlert.show,
                                  variant: "warning",
                                },
                              },
                              [_vm._v(_vm._s(_vm.scheduleAlert.message))]
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "mb-4",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "followup",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "text-primary",
                                                    attrs: { for: "followup" },
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v(
                                                        "Schedule Follow-up"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c("v-select", {
                                                  attrs: {
                                                    id: "followup",
                                                    "calculate-position":
                                                      _vm.withPopper,
                                                    label: "text",
                                                    options:
                                                      _vm.followUpOptions,
                                                    reduce: (followUpOptions) =>
                                                      followUpOptions.value,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "open-indicator",
                                                        fn: function ({
                                                          attributes,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "b-icon",
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    icon: "chevron-down",
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                },
                                                                "b-icon",
                                                                attributes,
                                                                false
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.selectedScheduleFollowupOption,
                                                    callback: function ($$v) {
                                                      _vm.selectedScheduleFollowupOption =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedScheduleFollowupOption",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "followup_date",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "text-primary",
                                                    attrs: {
                                                      for: "followup_date",
                                                    },
                                                  },
                                                  [
                                                    _c("small", [
                                                      _vm._v("Follow-up Date"),
                                                    ]),
                                                  ]
                                                ),
                                                _c("b-form-datepicker", {
                                                  attrs: {
                                                    id: "followup_date",
                                                    disabled:
                                                      _vm.selectedScheduleFollowupOption !==
                                                      "Manual",
                                                    "date-disabled-fn":
                                                      _vm.dateDisabled,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.scheduledFollowupDate,
                                                    callback: function ($$v) {
                                                      _vm.scheduledFollowupDate =
                                                        $$v
                                                    },
                                                    expression:
                                                      "scheduledFollowupDate",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          variant: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$bvModal.hide(
                                              "followupschedule"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "6" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          disabled: _vm.isProcessing,
                                          variant: "outline-primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(
                                              _vm.onFollowupSchedule
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.isProcessing
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _c("span", [_vm._v("Confirm")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }