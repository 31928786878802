<template>
  <b-modal
    id="rushPrescription"
    hide-header
    centered
    hide-footer
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
  >
    <h1 class="heading p-4 mb-0">Rush Prescription</h1>
    <b-row align-v="center" class="p-4">
      <b-col cols="12">
        Are you sure you want to immediately send this to the pharmacy?
      </b-col>
    </b-row>

    <b-row align-h="center" class="p-4">
      <b-col cols="6">
        <b-button
          pill
          block
          @click="$bvModal.hide('rushPrescription')"
          variant="primary"
          :disabled="processing"
        >
          Nevermind
        </b-button>
      </b-col>
      <b-col cols="6" class="mb-3">
        <b-button
          pill
          block
          :disabled="processing"
          @click="onPrescriptionRush"
          variant="outline-primary"
        >
          <b-spinner v-if="processing" small></b-spinner>
          <span v-else>Confirm</span>
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onPrescriptionRush() {
      this.$emit("rush");
    },
  },
};
</script>
