<template>
  <b-modal
    id="pauseDelayTimer"
    hide-header
    centered
    hide-footer
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    no-enforce-focus
    @hidden="onModalHide"
  >
    <h1 class="heading p-4 mb-0">Prescription Timer delay</h1>
    <b-row align-v="center" class="px-4 pb-4">
      <b-col cols="12"> What do you want to do with this prescription? </b-col>
    </b-row>

    <b-row align-h="center" class="p-4">
      <b-col cols="12" class="mb-3">
        <b-button
          pill
          block
          :disabled="processing"
          @click="onPauseDelay"
          variant="primary"
        >
          <b-spinner v-if="processing" small></b-spinner>
          <span v-else>Stop Delay</span>
        </b-button>
      </b-col>
      <!-- * Hidden for now. -->
      <!-- <b-col cols="12" class="mb-3">
        <b-button
          pill
          block
          :disabled="processing"
          @click="onRushPrescription"
          variant="primary"
        >
          <b-spinner v-if="processing" small></b-spinner>
          <span v-else>Push</span>
        </b-button>
      </b-col> -->
      <b-col cols="12">
        <b-button
          pill
          block
          @click="$bvModal.hide('pauseDelayTimer')"
          variant="outline-primary"
          :disabled="processing"
        >
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  sub,
  getUnixTime,
  parse,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isIndefinite: true,
      pausedUntil: null,
      hasError: false,
    };
  },
  watch: {
    pausedUntil(val) {
      if (val) {
        this.hasError = false;
      }
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    onPauseDelay() {
      this.$emit("stop-delay");
    },
    onRushPrescription() {
      this.$emit("rush-delay-timer");
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    onModalHide() {
      this.resetState();
    },
    resetState() {
      this.isIndefinite = true;
      this.pausedUntil = null;
    },
  },
};
</script>
