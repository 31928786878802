<template>
  <b-modal
    id="pause-progesterone-alert"
    hide-header
    hide-header-close
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <div class="icon-logo"></div>
      </b-col>
    </b-row>
    <div class="tw-float-right tw-w-full lg:tw-w-4/5">
      <div>
        <h2 class="mb-3 heading tw-text-2xl">
          You are pausing Progesterone to a patient while they are prescribed
          Estrogen. <br /><br />Do you want to continue?
        </h2>
      </div>

      <b-row class="my-4">
        <b-col cols="8" class="mb-2">
          <b-button
            block
            pill
            variant="primary"
            @click="$emit('prescribe')"
            :disabled="processing"
          >
            <b-spinner v-if="processing" small></b-spinner>
            <span v-else>Continue</span>
          </b-button>
        </b-col>
        <b-col cols="8">
          <b-button
            block
            pill
            variant="outline-primary"
            @click="$bvModal.hide('pause-progesterone-alert')"
          >
            <span>Cancel</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
