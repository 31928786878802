<template>
  <div>
    <b-modal
      id="addRefills"
      hide-header
      hide-header-close
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      @shown="onShow"
      @hidden="onHide"
    >
      <b-row class="mb-4">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="tw-float-right tw-w-full lg:tw-w-2/3">
        <div v-if="!isAddRefills">
          <h2 class="mb-3 heading tw-text-2xl">Do you want to...</h2>
          <div class="tw-mb-6">
            <b-button
              block
              pill
              variant="primary"
              @click="$bvModal.show('refillactivation')"
            >
              <span>Activate Refills</span>
            </b-button>
            <b-button
              block
              pill
              variant="outline-primary"
              @click="isAddRefills = true"
              :disabled="processing"
            >
              <span>Add Refills</span>
            </b-button>
            <b-button
              block
              pill
              variant="link"
              class="text-primary"
              @click="$bvModal.hide('addRefills')"
            >
              <span>Cancel</span>
            </b-button>
          </div>
        </div>

        <div v-if="isAddRefills">
          <h2 class="mb-3 heading tw-text-2xl">
            How many total refills would you like to add?
          </h2>
          <ValidationObserver ref="refillsForm" v-slot="{ handleSubmit }">
            <b-row>
              <b-col cols="12">
                <div role="group" class="tw-mb-6">
                  <label for="reason" class="tw-text-primary">Patient</label>
                  <p>{{ patientName }}</p>
                </div>
              </b-col>
              <b-col cols="12" class="tw-mb-6">
                <div role="group">
                  <label for="refilltype" class="tw-text-primary">
                    Refill Type
                  </label>
                </div>
                <v-select
                  class="refilltype"
                  placeholder="Select one"
                  :options="['One Year', 'Other']"
                  v-model="refilltype"
                ></v-select>
              </b-col>
              <b-col cols="12" class="tw-mb-6">
                <ValidationProvider
                  name="Refill"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div role="group">
                    <label for="reason" class="tw-text-primary">Refills</label>
                  </div>
                  <b-form-input
                    v-model.number="refills"
                    id="refill"
                    type="number"
                    min="0"
                    :disabled="refilltype === 'One Year'"
                    :readonly="refilltype === 'One Year'"
                    :formatter="refillInputFormatter"
                  ></b-form-input>
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </b-col>
              <b-col
                cols="12"
                class="tw-mb-6"
                v-if="
                  refilltype !== 'One Year' &&
                  followupdatestamp &&
                  !followupSentOrAnswered
                "
              >
                <div role="group">
                  <label class="tw-text-primary">Current Follow Up Date</label>
                </div>
                <p class="mb-0">
                  {{ parseDate(followupdatestamp, "MM-dd-yyyy") }}
                </p>
              </b-col>
              <b-col
                cols="12"
                class="tw-mb-6"
                v-if="refilltype !== 'One Year' && !followupSentOrAnswered"
              >
                <ValidationProvider
                  name="Keep Followup"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group class="mb-0">
                    <template #label>
                      <span class="text-primary">
                        Would you like to keep this follow-up appointment?
                      </span>
                    </template>
                    <b-form-radio-group
                      v-model="keepFollowup"
                      :options="[
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                      ]"
                      name="radio-inline"
                    ></b-form-radio-group>
                  </b-form-group>
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </b-col>
              <b-col
                cols="12"
                class="tw-mb-6"
                v-if="
                  (!keepFollowup || followupSentOrAnswered) &&
                  refilltype !== 'One Year'
                "
              >
                <ValidationProvider
                  name="New Follow Up Date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="newfollowupdate" class="tw-text-primary">
                    New Follow Up Date
                  </label>
                  <b-form-datepicker
                    id="newfollowupdate"
                    v-model="newfollowupdate"
                    :date-disabled-fn="dateDisabled"
                  ></b-form-datepicker>
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </b-col>

              <b-col cols="6" class="mb-6">
                <b-button
                  v-if="
                    prescription.refills === prescription.paidrefills ||
                    isBeforeScheduledDate
                  "
                  block
                  pill
                  variant="outline-primary"
                  @click="$bvModal.hide('addRefills')"
                >
                  Cancel
                </b-button>
                <b-button
                  v-else
                  block
                  pill
                  variant="outline-primary"
                  @click="isAddRefills = false"
                >
                  Back
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  block
                  pill
                  variant="primary"
                  @click="handleSubmit(onRefill)"
                  :disabled="processing"
                >
                  <b-spinner v-if="processing" small></b-spinner>
                  <span v-else>Refill</span>
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="refillactivation"
      hide-header
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <b-row style="position: relative">
        <b-col cols="12" sm="5" style="position: absolute; top: 0; left: 0">
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
              fill="#1A6A72"
            />
          </svg>
        </b-col>
        <b-col cols="12" sm="8" offset-sm="2" class="py-6">
          <h1 class="heading mb-4" style="line-height: 30px">
            Refill Activation
          </h1>
          <p class="mb-4">Are you sure you want to activate a refill?</p>
          <b-row class="mb-3">
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                @click="$bvModal.hide('refillactivation')"
                variant="outline-primary"
              >
                Nevermind
              </b-button>
            </b-col>
            <b-col cols="12" sm="6">
              <b-button
                pill
                block
                :disabled="processing"
                @click="onActivateRefills"
                variant="primary"
              >
                <b-spinner v-if="processing" small></b-spinner>
                <span v-else>Confirm</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import popper from "@/assets/js/popper.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  isBefore,
  fromUnixTime,
  getUnixTime,
  parse,
  add,
  sub,
  format,
  startOfToday,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export default {
  mixins: [popper],
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    patientName: {
      type: String,
      default: "",
    },
    scheduleddatetimestamp: {
      type: Number,
      default: 0,
    },
    followupdatestamp: {
      type: [Number, String],
      default: 0,
    },
    subscriptiontype: {
      type: String,
      default: "",
    },
    prescription: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      refills: 0,
      isAddRefills: false,
      refilltype: "One Year",
      newfollowupdate: null,
      keepFollowup: true,
    };
  },
  watch: {
    subscriptiontype(val) {
      if (val === "Monthly") {
        this.refills = 14;
      } else {
        this.refills = 5;
      }
    },
    refilltype(val) {
      if (val === "Other" && this.followupSentOrAnswered) {
        if (this.subscriptiontype === "Monthly") {
          this.newfollowupdate = format(
            add(new Date(), { days: this.refills * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else {
          this.newfollowupdate = format(
            add(new Date(), { days: this.refills * (3 * 28) - 7 }),
            "yyyy-MM-dd"
          );
        }
      }
    },
    refills(val) {
      if (this.followupSentOrAnswered) {
        this.newfollowupdate = format(
          add(new Date(), { days: this.refills * 28 - 7 }),
          "yyyy-MM-dd"
        );
      }
    },
  },
  computed: {
    isBeforeScheduledDate() {
      if (this.scheduleddatetimestamp) {
        return isBefore(new Date(), fromUnixTime(this.scheduleddatetimestamp));
      } else {
        return false;
      }
    },
    followupSentOrAnswered() {
      return this.followupSchedule.find(
        (followup) =>
          isBefore(
            getUnixTime(startOfToday(new Date())),
            followup.followupdatestamp
          ) &&
          (followup.followupstatus === "SENT" ||
            followup.followupstatus === "ANSWERED")
      );
    },
    ...mapState("patients", ["followupSchedule"]),
  },
  methods: {
    onRefill() {
      if (this.refilltype === "One Year") {
        this.$emit("refill", {
          refilltype: "ONE YEAR",
        });
      } else {
        this.$emit("refill", {
          refilltype: "OTHER",
          refills: this.refills,
          ...((!this.keepFollowup || this.followupSentOrAnswered) && {
            followupstamp: getUnixTime(
              zonedTimeToUtc(
                parse(this.newfollowupdate, "yyyy-MM-dd", new Date()),
                "GMT"
              )
            ),
          }),
        });
      }
    },
    onActivateRefills() {
      this.$emit("activate-refills");
    },
    onShow() {
      if (this.prescription.refilluponrequest) {
        this.refills = 12;
      } else {
        if (this.subscriptiontype === "Monthly") {
          this.refills = 14;
        } else {
          this.refills = 5;
        }
      }

      if (this.prescription.refills === this.prescription.paidrefills) {
        this.isAddRefills = true;
      }
    },
    onHide() {
      this.isAddRefills = false;
      this.$emit("hide-add-refills");
    },
    dateDisabled(ymd, date) {
      return sub(new Date(), { days: 1 }) > date;
    },
    parseDate(date, stringFormat) {
      if (date) {
        return format(fromUnixTime(date), stringFormat);
      }
    },
    refillInputFormatter(value) {
      return Math.floor(Math.abs(value));
    },
  },
};
</script>

<style>
.reason .vs__dropdown-menu {
  max-height: 200px !important;
}
</style>

<style scoped>
/deep/.form-control:disabled {
  background: rgb(238, 238, 238);
}
</style>
